import { find, get, includes, isNil, merge, pick } from 'lodash';
import Mexp from 'math-expression-evaluator';

import { format, getValueAsNumber, transform } from '../utils/ValueTypeFormatter';
import CalculationError, { CALC_ERRORS } from './CalculationError';
import { EXTERNAL_TYPES, NumericValueTypes, ValueType, VariableType, getAddressProperties } from './Variable';

export const rxCalculableColumns = /[[{][#%+]?([-_\w\d.]+)[}\]]/gim;

const FIELDS = [
  'id',
  'valueType',
  'property',
  'textTransform',
  'displayName',
  'isProjectLinkField',
  'multiline',
  'multilineValueOptions',
  'multilineValueLabels',
  'totalColumn',
  'calculated',
  'formula',
  'editable',
  'width',
  'decimals',
  'sourceVariable',
  'externalSelector',
];

// externalSelector values on inbound Filevine fields have the form of {sectionSelector}.{fieldSelector}.{propertySelector}
// For example: expenses.payee.firstName
// Outlaw's TableColumns are relative to the parent DataSource, which defines the collection (e.g., "expenses")
// So that part can be omitted -- hence the .slice(1)
// Then, we also want to handle object properties (currently limited to Contacts) as unique columns,
// so that (for example) multiple different properties of the same Contact object can both be included in the same Table/Repeater
// e.g., payee.firstName and payee.nickname (see Contact.js for full list of available properties)
// so re-joining on _ will ensure uniqueness
// expenses.payee.firstName --> payee_firstName
export const generatePropertyFieldID = (externalSelector) => {
  return externalSelector.split('.').slice(1).join('_');
};

export const DISALLOWED_CHARS = /[^_\w\d]/g;
export const sanitize = (name) => name.replace(DISALLOWED_CHARS, '');

export default class TableColumn {
  static fromConnectVariable(varDef, table) {
    const fieldID = generatePropertyFieldID(varDef.externalSelector);
    const isProjectLinkField = varDef.externalType === EXTERNAL_TYPES.PROJECT;
    const displayName = isProjectLinkField ? varDef.displayName : varDef.displayName.split(' - ').pop();
    return new TableColumn(
      merge({}, varDef, {
        id: fieldID,
        displayName,
        sourceVariable: varDef.name,
        isProjectLinkField,
        multiline: varDef.multiline,
        multilineValueOptions: varDef.multilineValueOptions,
        multilineValueLabels: varDef.multilineValueLabels,
        externalSelector: varDef.externalSelector,
      }),
      table
    );
  }

  id; // key of json data; same as column name for legacy
  valueType; // one of Variable ValueType values; enables property selection for CURRENCY / NUMBER / DATE
  property; // e.g., 'verbose' for DATE vars or 'spelled' for NUMBER vars
  textTransform; // e.g., 'uppercase'
  displayName; // title of column if different than id (identical to id for legacy)

  // When actual connected Variables are used to create TableColumns on the fly via fromConnectVariable() above,
  // This lets us keep track of the true original target Variable
  // This way, when an external object (e.g., collection item) is selected (e.g., in DataSourceBrowser),
  // it becomes trivial to map that object's properties back to target connected Variables (see DataSourceBrowser.save())
  // Note, it's intentionally not included in the FIELDS const because this property shouldn't ever be stored
  sourceVariable;

  // Not yet used, but will be once we enable "advanced" tables for Outlaw TABLE vars
  multiline; // for string columns, whether to enable/render multiline input
  editable; // whether column can be edited (always true for legacy)
  // width;          // flex-based number (probably 1-12)

  // Reference to the parent table (Variable) instance
  table;
  // if the field is derivered from a project link
  isProjectLinkField = false;
  multilineValueOptions = {};
  multilineValueLabels = {};
  totalColumn = false;

  calculated = false;
  formula = null;
  _width = 3; // Small, Medium (default), Large => [2,3,4]
  decimals = null;
  externalSelector;

  constructor(json, table) {
    this.table = table;

    this.id = get(json, 'id', null);
    this.valueType = get(json, 'valueType', 'string');
    this.property = get(json, 'property', null);
    this.textTransform = get(json, 'textTransform', null);
    this.displayName = get(json, 'displayName', true);

    this.sourceVariable = get(json, 'sourceVariable', null);

    this.isProjectLinkField = get(json, 'isProjectLinkField', false);

    this.multiline = get(json, 'multiline', false);
    this.multilineValueOptions = get(json, 'multilineValueOptions', {});
    this.externalSelector = get(json, 'externalSelector', null);
    this.multilineValueLabels = get(json, 'multilineValueLabels', {});
    this.totalColumn = get(json, 'totalColumn', false);
    this.calculated = get(json, 'calculated', null);
    this.formula = get(json, 'formula', null);
    this.editable = get(json, 'editable', true);
    this.width = get(json, 'width', 3);
    this.decimals = get(json, 'decimals', null);
  }

  formatValue(rawValue, row = {}) {
    let formattedVal, val;

    if (this.calculated && this.formula) {
      const result = this.calculate({ row });
      // console.log(`Format [${result}] as [${this.valueType}]`);
      formattedVal = format(result, this.valueType, null, { sigDigits: this.decimals, languageCode: 'en-US' });
    }
    // Otherwise just format the raw json value
    else {
      formattedVal = format(rawValue, this.valueType, this.property, {
        sigDigits: this.decimals,
        languageCode: 'en-US',
      });
    }

    val = transform(formattedVal, this.textTransform);

    // format() can return null values, but in this context, we always need a string for display
    // so return an empty string here if null (otherwise it renders the word "null")
    if (isNil(val)) return '';
    if (typeof val !== 'string') {
      try {
        const rawType = Array.isArray(rawValue) ? 'array' : typeof rawValue;
        console.log(`Column [${this.table.name}.${this.id}] expected [${this.valueType}] but got [${rawType}]`);
        return JSON.stringify(val);
      } catch (er) {
        return '';
      }
    }
    return val;
  }

  // This copied and adapted to follow the same pattern as Variable.calculate()
  // It's similarly recursive, but calculated columns can also reference other columns in their formulas,
  // similar to Repeaters, using the {field} syntax in curly braces
  calculate({ stack = [], simpleStack = [], colStack = [], row = {} }) {
    if (colStack.includes(this.id)) {
      throw new CalculationError(CALC_ERRORS.CIRCULAR);
    }

    colStack.push(this.id);

    let result, matchVar;
    let replacedFormula = this.formula;
    const rxCalc = new RegExp(rxCalculableColumns, 'gim');

    // Find each symbol referenced in the formula so that we can replace with underlying values
    while ((matchVar = rxCalc.exec(this.formula)) !== null) {
      let val;
      const varName = matchVar[1];

      // If the match starts with a '{', we're referencing another column in this same table,
      // ie another value in the same row
      if (matchVar[0][0] === '{') {
        const targetCol = find(this.table.columns, { id: varName });

        // If there's no column with that name, throw same invalid reference error as normal calculated variables
        if (!targetCol) {
          throw new CalculationError(CALC_ERRORS.REF, varName);
        }
        // Here we've successfully found a valid target column. If it's also calculated, we need to recurse
        if (targetCol.calculated) {
          val = targetCol.calculate({
            stack: [...stack],
            simpleStack: [...simpleStack],
            colStack: [...colStack],
            row,
          });
        }
        // Otherwise we can just grab the value and replace in formula
        else {
          // Treat empty data as 0
          if (!row[varName]) val = 0;
          else val = getValueAsNumber(String(row[varName]));

          if (targetCol.valueType === ValueType.PERCENT) val /= 100;
        }
      }
      // Calculated columns can also reference normal vars
      // so this is a slightly simplified version of Variable.calculate and comments removed
      // because TableColumns DO have their valueTypes explicitly specified
      else {
        let targetVar = this.table.deal.variables[varName];

        // Check to see if we are referincing another table var column total (e.g. fv_meds.amount)
        if (!targetVar) {
          const [colVarName, colID] = varName.split('.');

          if (colVarName && colID) {
            const dealVariable = find(this.table.deal.variables, { name: colVarName });
            const col = find(dealVariable.columns, { id: colID });

            if (col.totalColumn) {
              val = col.total;
            }

            if (!val) {
              if (dealVariable.type === VariableType.CONNECTED) {
                throw new CalculationError(CALC_ERRORS.DATA, colVarName);
              } else {
                throw new CalculationError(CALC_ERRORS.REF, varName);
              }
            }
          }
        } else {
          if (targetVar.type === VariableType.CALCULATED) {
            const calculated = targetVar.calculate(targetVar.formula, [...stack], [...simpleStack]);

            val = calculated.result;
          } else if (targetVar.isTableTotal) {
            const col = targetVar.relatedTotalColumn;

            if (col) {
              val = col.total;
            }
          } else {
            if (!targetVar.val) val = 0;
            else val = getValueAsNumber(String(targetVar.value));

            simpleStack.push(targetVar.name);
          }

          if (targetVar.valueType === ValueType.PERCENT) val /= 100;
        }
      }
      // Now we've gathered values for all the referenced variables, so replace the references with the values
      replacedFormula = replacedFormula.replace(matchVar[0], val);
    }

    try {
      result = new Mexp().eval(replacedFormula);
    } catch (err) {
      throw new CalculationError(CALC_ERRORS.FORMULA);
    }
    if (result === Infinity) {
      throw new CalculationError(CALC_ERRORS.DIV0);
    }

    return result;
  }

  get total() {
    let tableData = this.table.tableValueFormatted;
    if (!Array.isArray(tableData)) tableData = [];

    if (!NumericValueTypes.includes(this.valueType)) return;
    if (tableData?.length < 1) return 0;

    const total = tableData?.reduce((acc, row) => {
      if (this.calculated) {
        return acc + this.calculate({ row });
      }
      const valueToSum = row[this.id];
      if (!valueToSum) {
        return acc;
      }
      // If we can parse numeric values successfully, return that;
      // otherwise just return raw value
      const num = Number.parseFloat(valueToSum?.toString()?.replace(/[$,%]/g, ''));
      if (!isNaN(num)) {
        return acc + num;
      }
      return acc;
    }, 0);

    return total;
  }

  get width() {
    return this._width;
  }

  set width(_width) {
    const numericWidth = parseInt(_width);
    if (!isNaN(numericWidth) && numericWidth > 0) {
      this._width = numericWidth;
    } else {
      this._width = 1;
    }
  }

  // For most fields, this is identical to the id, but for Contact properties,
  // the Filevine fieldSelector is just the first piece
  // e.g., if we're requesting both a payee_nickname and payee_department from a expenses collection item,
  // We only need to ask Filevine API for the expenses.payee, then can fill in both properties after the fact
  get fieldSelector() {
    return this.id.split('_')[0];
  }

  get isAddressBasedColumn() {
    return _.includes(this.sourceVariable, 'addresses');
  }

  allowSettingAddressInstances(valuesList) {
    //A connected address must have specific properties to be able to set it accross all instances.
    //subprop must be line1, line2, fullAddress or not specificed (defaulting to full address).
    if (this.externalSelector && includes(this.externalSelector, '.addresses') && valuesList?.length > 1) {
      const { subprop, modifier } = getAddressProperties(this.externalSelector);

      if (!modifier && includes(['line1', 'line2', 'fullAddress', undefined], subprop)) {
        return true;
      } else if (modifier && includes(['line1', 'line2', 'fullAddress', undefined], modifier)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get json() {
    return pick(this, FIELDS);
  }

  get isValid() {
    if (this.calculated) {
      try {
        this.total;
      } catch (e) {
        return false;
      }
    } else {
      try {
        this.formatValue();
      } catch (e) {
        return false;
      }
    }

    return true;
  }

  multilineValueLabelsMap(colLabels) {
    if (Array.isArray(colLabels)) {
      return colLabels?.reduce((acc, valLabel) => {
        if (Array.isArray(valLabel) && valLabel.length >= 2) {
          const [val, label] = valLabel;
          return { ...acc, [val]: label };
        }
        return acc;
      }, {});
    } else {
      return colLabels;
    }
  }
}

import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { TEAM_ROLES } from '@core/models/Team';
import { dt } from '@core/utils/Environment';
import { isEmail } from '@core/utils/Validation';

import { Button, Checkbox, Dropdown, Loader, MenuItem, Switch } from '@components/dmp';

import { OBSERVER_ROLES } from '@components/deal/DealUserBlock';
import API from '@root/ApiClient';

const DEFAULT_ROLE = TEAM_ROLES.VIEWER;

@autoBindMethods
export default class MemberInfo extends Component {
  static defaultProps = {
    showTeamID: false,
  };

  static propTypes = {
    member: PropTypes.object,
    user: PropTypes.object.isRequired,
    team: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
    showTeamID: PropTypes.bool,
  };

  constructor(props) {
    const { member } = props;
    super(props);

    this.state = {
      email: _.get(member, 'email', ''),
      role: _.get(member, 'role', DEFAULT_ROLE.value),
      id: _.get(member, 'id', null),
      observer: _.get(member, 'observer', false),
      observerRole: _.get(member, 'observerRole', null),
      canCreateNewDoc: _.get(member, 'canCreateNewDoc', true),
      serviceProvider: _.get(member, 'serviceProvider', null),
      withoutRedline: _.get(member, 'withoutRedline', false),
      loading: false,
    };
    this.memberEmailRef = createRef();
  }

  componentDidUpdate(prevProps) {
    const { member } = this.props;

    if (!_.isEqual(member, prevProps.member)) {
      this.populate(member);
    }
  }

  populate(member) {
    this.setState({
      email: _.get(member, 'email', ''),
      role: _.get(member, 'role', DEFAULT_ROLE.value),
      observer: _.get(member, 'observer', false),
      observerRole: _.get(member, 'observerRole', null),
      canCreateNewDoc: _.get(member, 'canCreateNewDoc', true),
      withoutRedline: _.get(member, 'withoutRedline', false),
      serviceProvider: _.get(member, 'serviceProvider', null),
      id: _.get(member, 'id', null),
      loading: false,
    });
  }

  focusInput() {
    if (this.memberEmailRef.current) {
      ReactDOM.findDOMNode(this.memberEmailRef.current).focus();
    }
  }

  async addMember() {
    const { onSave, user, team } = this.props;
    const { email, id, role, observer, observerRole, canCreateNewDoc, serviceProvider, withoutRedline } = this.state;

    this.setState({ loading: true });

    const memberProps = {
      email,
      id,
      role: role || DEFAULT_ROLE.value,
      observer,
      canCreateNewDoc,
      withoutRedline: withoutRedline ? withoutRedline : null,
      observerRole: observer ? observerRole : null,
      serviceProvider,
    };

    try {
      const uid = await API.call('addTeamMember', {
        teamID: team.teamID,
        ...memberProps,
      });
      this.setState({ loading: false });

      if (uid) {
        memberProps.uid = uid;
      }

      onSave(memberProps);
    } catch (err) {
      console.log('addTeamMember.ERR', err);
      const status = _.get(err, 'response.status', null);
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { member, onHide, multiOwners, team, showTeamID } = this.props;
    const { role, id, email, observer, observerRole, loading, canCreateNewDoc, serviceProvider, withoutRedline } =
      this.state;

    let memberRole = role ? _.find(TEAM_ROLES, { value: role }) : TEAM_ROLES.VIEWER;
    let memberObserverRole = observerRole ? _.find(OBSERVER_ROLES, { key: observerRole }) : OBSERVER_ROLES[0];
    const isNew = id == null;
    const isInvite = member ? member.inviteID != null : false;
    //do not show the roles dropdown if there is only one owner and the member being edited is that owner. We can't allow them to change their role.
    const showRolesDropdown = member ? !isNew && !multiOwners && member.role === TEAM_ROLES.OWNER.value : false;

    return (
      <Modal
        dialogClassName="add-team-member"
        show={!!member}
        onHide={onHide}
        onEnter={this.focusInput}
        data-cy="add-team-member-modal"
      >
        <Modal.Header closeButton>
          <span className="headline">
            {isNew ? 'Add a new member' : 'Update member options'}
            {showTeamID && (
              <>
                <br />
                <small>({team.teamID})</small>
              </>
            )}
          </span>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Email</ControlLabel>
            <FormControl
              className="txt-email"
              type="text"
              ref={this.memberEmailRef}
              disabled={loading || !isNew}
              value={email}
              placeholder="Enter email address"
              onChange={(e) => this.setState({ email: e.target.value })}
              data-cy="txt-email"
            />
          </FormGroup>

          {(!showRolesDropdown || isInvite) && (
            <FormGroup>
              <ControlLabel>Team Role</ControlLabel>
              <div className="contents">
                <Dropdown
                  id="dd-team-member-role"
                  disabled={loading}
                  title={memberRole.title}
                  onSelect={(role) => this.setState({ role })}
                  data-cy="dd-team-member-role"
                >
                  {_.map(TEAM_ROLES, (r, idx) => (
                    <MenuItem key={idx} eventKey={r.value} active={r.value === role}>
                      {r.title}
                    </MenuItem>
                  ))}
                </Dropdown>
                <small>{memberRole.description}</small>
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <ControlLabel>Access</ControlLabel>
            <div className="contents observer">
              <Checkbox
                id="chk-tm-observer"
                checked={observer}
                onChange={() => this.setState({ observer: !observer })}
                data-cy="chk-tm-observe"
              >
                Observer
              </Checkbox>
              <small>Observers can see all {dt}s created from any of this team's templates</small>
            </div>
          </FormGroup>

          {observer && (
            <FormGroup>
              <ControlLabel></ControlLabel>
              <div className="contents observer-role">
                <div className="observer-role-title">When opening an observed document, set user's role to:</div>
                <Dropdown
                  id="dd-team-member-observer-role"
                  disabled={loading}
                  title={memberObserverRole.title}
                  onSelect={(observerRole) => this.setState({ observerRole })}
                  dataCyToggle="dd-team-member-observer-role"
                >
                  {_.map(OBSERVER_ROLES, (r, idx) => (
                    <MenuItem key={idx} eventKey={r.key} active={r.value === role}>
                      {r.title}
                    </MenuItem>
                  ))}
                </Dropdown>
                <small>{memberObserverRole.description}</small>
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <ControlLabel></ControlLabel>
            <div className="contents new-doc">
              <Checkbox
                id="chk-tm-doc-creation"
                checked={canCreateNewDoc}
                onChange={() => this.setState({ canCreateNewDoc: !canCreateNewDoc })}
                dataCy="chk-tm-doc-creation"
              >
                New Docs
              </Checkbox>
              <small>{`User can create (and duplicate existing) documents`}</small>
            </div>
          </FormGroup>

          {role && (
            <FormGroup>
              <ControlLabel></ControlLabel>
              <div className="contents redline-default" data-cy="redline-default">
                <Checkbox
                  id="chk-tm-default-redline"
                  checked={withoutRedline}
                  onChange={() => this.setState({ withoutRedline: !withoutRedline })}
                  dataCy="chk-tm-default-redline"
                >
                  Edit without redlining by default
                </Checkbox>
                <small>Enabling this setting turns off redlining when this member edits a section</small>
              </div>
            </FormGroup>
          )}

          {team.features.serviceProviders && (
            <FormGroup>
              <ControlLabel></ControlLabel>
              <div className="contents service-provider">
                <Checkbox
                  id="chk-tm-service-provider"
                  checked={serviceProvider}
                  onChange={() => this.setState({ serviceProvider: !serviceProvider })}
                  data-cy="chk-tm-service-provider"
                >
                  Service Provider
                </Checkbox>
                <small>{`User can access custom workflow steps.`}</small>
              </div>
            </FormGroup>
          )}
        </Modal.Body>

        <Modal.Footer>
          {loading && <Loader />}
          <Button disabled={loading} onClick={onHide}>
            Cancel
          </Button>
          <Button
            dmpStyle="primary"
            disabled={!isEmail(email) || loading}
            onClick={this.addMember}
            data-cy={isNew ? 'btn-add-team-member' : 'btn-save-team-member'}
          >
            {isNew ? 'Add Member' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import jump from 'jump.js';
import _ from 'lodash';

import DealAction from '@core/enums/DealAction';
import DealRole from '@core/enums/DealRole';
import DealStatus from '@core/enums/DealStatus';
import { FILEVINE_SERVICE } from '@core/enums/IntegrationServices';
import { ATTACHMENT_TYPE, STORAGE_TYPES } from '@core/models/Attachment';
import Bundle from '@core/models/Bundle';
import { mergeFields } from '@core/models/filevine/Contact';
import AIBlockRunner from '@core/utils/AIBlockRunner';
import { Dt, dt, isVine } from '@core/utils/Environment';
import Stopwatch from '@core/utils/Stopwatch';

import { Dropper, Icon, Loader, ModalConfirm } from '@components/dmp';

import DocRequest from '@components/DocRequest';
import NoDealAccess from '@components/NoDealAccess';
import PromoFooter from '@components/PromoFooter';
import SummaryView from '@components/SummaryView';
import AttachmentUploader from '@components/deal/AttachmentUploader';
import DealFooter from '@components/deal/DealFooter';
import DealHeader from '@components/deal/DealHeader/DealHeader';
import ReadOnlyDealHeader from '@components/deal/ReadOnlyDealHeader';
import ReadOnlyDealModal from '@components/deal/ReadOnlyDealModal';
import MegaView from '@components/debug/MegaView';
import TimevineOutlaw from '@components/timevine/TimevineOutlaw';
import API from '@root/ApiClient';
import Fire from '@root/Fire';
import ErrorView from '@root/routes/ErrorView';
import { TimelineEventEditor } from '@root/routes/TimelineView';
import VariableIndex from '@root/utils/VariableIndex';
import PDFView from '@routes/PDFView';
import SourceViewVirtual from '@routes/SourceViewVirtual';
import TemplateEditor from '@routes/TemplateEditor';

@autoBindMethods
class DealView extends Component {
  // TODO: List down Props and default Props

  constructor(props) {
    super(props);

    this.state = {
      noAccess: false,
      deal: null,
      bundle: null,
      selectedVersion: null,
      summary: null,
      headers: null,
      footers: null,
      source: null,
      toc: null,
      template: null,
      activityMode: 'open',
      showActivity: [],
      loading: true,
      logging: false, //to ensure we don't add duplicate CREATE and VIEW events on load
      showDealStatus: false,
      notifyChanges: false,
      showReadOnlyModal: false,
      trackChanges: null,
      versionHistory: null, //when populated with section, history modal will show
      deepLinking: false,
      hasClosedReadOnlyModal: false,
      scale: 1.5, //only used in PDFView, but controlled via DealHeader so needs to be in state here
      pdfEditMode: null,
      pdfField: null,
      pdf: null, //set via PDFView, raw pdfjs pdf object, used in DealHeader
      // For dropping new versions directly onto deal -- exactly copying pattern from DealsPage
      droppedFile: null,
      showUploader: false,
      showBundle: false,
      // connectFields is the full list of connected fields *available* for use on the 3rd-party system (e.g., Filevine)
      connectFields: null,
      fontsInUse: [],
      fontsLoaded: false,
      error: null,
      deleted: false,
      dealDeleted: false,
      //blockID is used when generating AI block content in sidebar.
      blockID: null,
      signed: false,
    };

    this.sw = new Stopwatch('DealView');
    this.variableIndex = new VariableIndex();

    this.refHeader = createRef();

    // TemplateEditor explicitly shows a message when external (Filevine) fields are loaded via getConnectFields
    // Pushing it via ref is simpler than using componentDidUpdate checks
    this.refDraft = createRef();

    // Enable cross-component access to better encapsulate PDFReview panel
    this.refPDF = createRef();

    this.refTimevine = createRef();

    this._isMounted = true;
  }

  get dealID() {
    return _.get(this.props, 'match.params.dealID', null);
  }

  componentDidMount() {
    this.loadDeal(this.dealID);
  }

  componentWillUnmount() {
    this._isMounted = false;

    // Turn off listener when user navs away from page in order to prevent memory leak!!
    this.stopListening(this.dealID);
  }

  UNSAFE_componentWillReceiveProps(props) {
    // Special case when user log out from the page, redirect to login page.
    if (this.props.user && !props.user) {
      this.props.history.push(`/login`);
    }

    const nextDealID = _.get(props, 'match.params.dealID', null);

    if (this.dealID !== nextDealID && nextDealID) {
      this.stopListening(this.dealID);
      this.loadDeal(nextDealID);
    }

    //this.handleDeepLink(props);
    this.logEvents(this.state.deal, props.user, _.get(props, 'match.params.mode', 'default'));
  }

  componentDidUpdate(prevProps, prevState) {
    const connections = _.get(this.state.deal, 'connections', []);
    const prevConnections = _.get(prevState.deal, 'connections', []);

    // If there's no external connection (standard case), nothing to do
    if (connections.length || prevConnections.length) {
      // Fetch externally available fields for a connected Deal or Template
      // We'll hit this case on initial load of a connected Deal, or when a new connection is added via TemplateInfo
      // Currently only relevant to Filevine but we can easily broaden to other sources (e.g., Salesforce)
      if (!prevConnections.length && connections.length) {
        this.getConnectFields(this.state.deal);
      }
      // If we HAD a previous connection that was removed (via TemplateInfo), reset index
      else if (prevConnections.length && !connections.length) {
        console.log('DealConnection removed -- remove external fields from VariableIndex');
        this.setState({ connectFields: null });
        this.updateVariableIndex(this.state.deal);
      }
      // If connection config changed (via TemplateInfo), also re-fetch fields and update index
      else if (!_.isEqual(_.map(prevConnections, 'json'), _.map(connections, 'json'))) {
        console.log('DealConnection changed -- refresh available fields');
        this.getConnectFields(this.state.deal, true);
      }
    }
  }

  handleDeleteDeal() {
    this.setState({ dealDeleted: true });
  }

  async confirmDeleteDeal() {
    const { history } = this.props;
    await API.call('deleteDeal', { dealID: this.dealID });
    history.push('/dashboard/contracts');
  }

  loadDeal(dealID) {
    const { user, history } = this.props;

    Fire.getDeal(
      dealID,
      async (deal) => {
        this.sw.reset();
        this.sw.step('Got deal data from Firebase');

        if (deal.deleted) {
          this.sw.step('Deal has been deleted');
          this.setState({ deleted: true, deal, loading: false });
          return;
        }

        //anytime we get an updated deal object, also generate other views for re-use
        const { hasClosedReadOnlyModal } = this.state;
        const summary = deal.buildSummary();
        const headers = deal.buildHeaders();
        const footers = deal.buildFooters();
        const source = deal.buildSource(true);
        const toc = deal.buildTOC();
        const template = deal.template || null;
        const activity = deal.buildActivityStats(source);

        // Retrieve the computed signed & locked value to improve performance
        // They are an expensive computation
        const signed = deal.signed;
        const locked = deal.locked;

        //by default only show comments if deal is not locked yet
        const activityMode = signed ? 'none' : 'open';
        // const activityMode = activity.open > 0 || activity.all == 0 ? 'open' : 'none';

        // Custom fonts
        const fontsInUse = this.getFontsInUse(deal);

        // If the Deal is part of a Bundle (whether parent or child),
        // We need to load in the Bundle model as a second step and attach to Deal.bundle
        // This way every sub-component of DealView has access to the Bundle
        if (deal.isBundle && !isVine) {
          await this.loadBundle(deal);
        }

        // If Deal has connected vars, is not locked, and is the first load of this deal, load/sync latest external values
        const syncedVars = _.keys(deal.syncedVariables);
        if (syncedVars.length > 0 && !deal.locked && !deal.isTemplate && this.state.deal?.dealID !== deal.dealID) {
          this.loadConnectVariables(deal);
        }

        let tocKey = '';
        source.map((sec, idx) => (tocKey += `${sec.id}-${idx}-${sec.displayNumber || ''}|`));
        this.sw.step('Computed all state vars for DealView');
        this.sw.step(`Deal status: computed=${deal.status.data}, stored=${deal.info.status}`);

        // Build variable index to power VariableSuggest
        // On first load of connected deals, externally available fields will not have loaded yet
        // so the Deal will be the only data source for the VariableIndex
        // On subsequent deal loads (as deal data changes), we only need to update the index if stored vars have changed
        if (_.get(this.state, 'deal.suggestVariablesKey') !== deal.suggestVariablesKey) {
          this.updateVariableIndex(deal, this.state.connectFields);
        }

        if (!this.state.fontsLoaded) {
          const fontsLoaded = await this.loadCustomFonts(fontsInUse);
          this.sw.step('Loaded fonts');
        }

        this.setState({
          deal,
          summary,
          headers,
          footers,
          source,
          toc,
          template,
          activity,
          activityMode,
          tocKey,
          loading: false,
          fontsLoaded: true,
          signed,
          locked,
          // If user's without redline team setting is enabled, then trackChanges should be disabled
          defaultRedline: user.withoutRedline(deal.team) ? false : true,
        });

        if (!this.accessCheck() && !deal.readonly) {
          this.setState({ noAccess: true, loading: false });
          return;
        }

        // Autorun any AIBlocks, if present
        AIBlockRunner.autorun(source);

        // If we're read-only and there is no user OR currentDealUser, switch to readonly mode
        if ((deal.readonly && !user) || (deal.readonly && !deal.currentDealUser)) {
          history.replace(`/deals/${deal.dealID}/readonly`);
        }

        if (!hasClosedReadOnlyModal && this.mode === 'readonly' && !this.accessCheck()) {
          this.setState({ showReadOnlyModal: true });
        }

        // If we're viewing the root deal view without a mode specified,
        // push either to /editor (if it's actually a template),
        // or to /overview (if content exists)
        // or to /contract view if no overview content exists
        // This ensures that mode ALWAYS shows up in the final (user-accessible) URL
        // and we use history.replace to ensure that hitting "back" will skip the modeless URL
        if (this.mode === 'default') {
          if (deal.isTemplate) {
            history.replace(`/deals/${deal.dealID}/editor`);
          } else if (summary.length && deal.currentVersion.isOriginal && !deal.workflow.serviceProviders) {
            history.replace(`/deals/${deal.dealID}/overview`);
          } else if (
            deal.currentVersion.isOriginal &&
            deal.workflow.serviceProviders &&
            !deal.currentWorkflowStep.isAfterRestricted
          ) {
            history.replace(`/deals/${deal.dealID}/casedata`);
          } else if (deal.hasVersions) {
            history.replace(deal.currentVersion.deepLink);
          } else {
            history.replace(`/deals/${deal.dealID}/contract`);
          }
        }

        //this.handleDeepLink(this.props);
        this.logEvents(deal, user, this.mode);
      },
      // Maintain active realtime connection (for Deal changes)
      true,
      // Handle security error if user does not have access
      async () => {
        // If we get here, first check to see if the user can observe this deal
        // If API call returns true, it means user is observer and has been added to deal
        // so reload will work this time
        let canObserve = false;
        let error = null;
        if (user) {
          try {
            canObserve = await API.call('observe', { dealID });
          } catch (er) {
            error = er;
          }
        }
        //API.observe can throw a 403 (no user/access error) or 404 (deal not found)
        if (canObserve) {
          this.loadDeal(dealID);
        } else if (!user || error?.code !== 404) {
          this.setState({ noAccess: true, loading: false });
        } else {
          this.setState({ error: error, loading: false });
        }
      }
    );
  }

  async loadBundle(deal) {
    let bundleRaw;

    // If we're already loading a bundle, don't keep calling the API over and over
    // Fire.updateDealInfo internally calls API.syncConnectedDealInfo, which changes Deal data, which triggers another Fire.getDeal
    // So this is to avoid some API.getBundle from getting called repeatedly as a result
    if (this._bundleLoading) {
      const { bundle } = this.state;
      // If we've got one already loaded from state with the same dealID, attach that one
      if (deal.isBundle && bundle && deal.bundleID === bundle.parent.dealID) {
        deal.bundle = bundle;
      }
      return;
    }

    try {
      this._bundleLoading = true;
      bundleRaw = await API.call('getBundle', { dealID: deal.bundleID });
    } catch (error) {
      console.log(error);
      this.setState({ error: error });
    }

    if (bundleRaw) {
      const bundle = new Bundle(bundleRaw.parent, bundleRaw.children);
      // Store latest bundle in state so that we don't keep hitting the API if it's loading
      await this.setState({ bundle });
      deal.bundle = bundle;
      this._bundleLoading = false;
    } else {
      this._bundleLoading = false;
      await this.setState({ bundle: null });
    }
  }

  getFontsInUse(deal) {
    const fontsInUse = [];

    Object.keys(deal.style.type).forEach((t) => {
      const newFont = deal.style.type[t].native.font;

      if (!fontsInUse.includes(newFont)) fontsInUse.push(newFont);
    });

    return fontsInUse;
  }

  async loadCustomFonts(fonts) {
    return new Promise((resolve) => {
      if (!('fonts' in document)) resolve([]);

      const fontsLoaded = [];
      let promises = [];

      fonts.forEach((font) => {
        const fontPromises = [
          document.fonts.load(`12px "${font}"`),
          document.fonts.load(`italic 12px "${font}"`),
          document.fonts.load(`bold 12px "${font}"`),
          document.fonts.load(`bold italic 12px "${font}"`),
        ];

        promises = promises.concat(fontPromises);
      });

      Promise.all(promises)
        .then((fontFaces) => {
          fontFaces.forEach((fontFace) => {
            if (!fontFace.length) return; //Bypass system fonts

            if (!fontsLoaded.includes(fontFace[0].family)) fontsLoaded.push(fontFace[0].family);
          });

          resolve(fontsLoaded);
        })
        .catch((e) => {
          console.log('Something went wrong while loading fonts', e);
          resolve(fontsLoaded);
        });
    });
  }

  async loadConnectVariables(deal) {
    const { currentConnectVars } = this.state;

    if (!deal.isConnected) {
      this.sw.step('Skipped Connect variable loading (no connection)');
      return;
    } else if (!Object.keys(deal.syncedVariables).length) {
      this.sw.step('Skipped Connect variable loading (no connected variables)');
      return;
    }

    // If connected var values differ differ from what we've stored in state (e.g., on first Deal load), load latest values via API
    if (!_.isEqual(currentConnectVars, deal.syncedValues)) {
      if (currentConnectVars) {
        console.log('Reloading connect variables');
      }

      try {
        const latestConnectVars = await API.call('syncConnectVariables', { dealID: deal.dealID });
        // On initial load, track timing it took to load
        if (!currentConnectVars) {
          this.sw.step('Loaded connect variables');
        }
        // Store values in state so that we don't keep unnecessarily hitting the API for Deal changes that don't involve connected vars
        this.setState({ currentConnectVars: latestConnectVars });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.sw.step('Skipped Connect variable loading (nothing changed)');
    }
  }

  // Initialize a variableIndex that can contain the current Deal variables
  // as well as "potential" new Variables powered by Connections on the Template
  // e.g Fetching the Filevine Fields and pushing them to this index.
  async updateVariableIndex(deal, connectFields) {
    if (!deal) return;
    const variables = [...deal.suggestVariables, ...(connectFields || [])];

    this.variableIndex.reset();
    this.variableIndex.add(variables);
    this.sw.step('Updated variable index');
  }

  async getConnectFields(deal, forceRefresh = false) {
    // If we already loaded the connect fields for this deal, don't keep reloading them as Deal data changes
    let { connectFields } = this.state;
    if (connectFields && !forceRefresh) return;

    // TODO: once DealConnection panel is released, add to IntegrationServices enum
    // to specify which services support external field loading (for now it's only FV so just hardcode)
    const dealConnection = _.find(deal.connections, { type: FILEVINE_SERVICE.key });
    if (!dealConnection) return;

    try {
      if (this.refDraft.current) {
        this.refDraft.current.updateMessage(
          <>
            <Loader dark size="small" className="loader" /> Syncing connected fields...
          </>,
          null,
          false
        );
      }
      const allFields = await API.call('getConnectFields', {
        dealID: deal.dealID,
        dealConnection: dealConnection.json,
      });
      connectFields = allFields.connectFields || [];

      // Merge field definition into FV Contact app-wide
      // we don't need to use state here; every time this is called it will refresh field list with the latest
      mergeFields(allFields.contactFields);

      this.sw.step(`Fetched [${connectFields.length}] fields from Filevine API`);

      // When loading available fields in TemplateEditor, push a message to show they've loaded
      if (this.refDraft.current) {
        this.refDraft.current.updateMessage(
          <>
            <Icon name="logoFilevine" dark /> Fields loaded
          </>,
          'connect-success',
          true
        );
      }
    } catch (err) {
      console.log(err.message);
      if (this.refDraft.current) {
        this.refDraft.current.updateMessage('Error loading Filevine Fields', 'error', true);
      }
    }

    if (this._isMounted) {
      await this.updateVariableIndex(deal, connectFields);
      this.setState({ connectFields });
    }
  }

  stopListening(dealID) {
    Fire.db.ref(`deals/${dealID}`).off();
    this.variableIndex.reset();
    if (this._isMounted) {
      this.setState({ connectFields: null, currentConnectVars: null });
    }
  }

  /*
    This section has been disabled since we've implemented
    virutal sections rendering.
    Keeping it here for now, but we'll likely remove or fix later
  */
  handleDeepLink(props) {
    const { mode, sectionID, dealID } = props.match.params;
    const { deal, deepLinking } = this.state;

    // If deal hasn't loaded yet do nothing -- this will fire again once deal loads
    // or if we're in process of loading a deep link, make sure we don't repeat scroll
    if (!deal || deepLinking) return;

    // In the event that a user navs *directly* from one deal to another, e.g., in the case of copying a deal,
    // we need to stop listening on prior deal and load the new one.
    // The following routine below for section linking will never fire in that case,
    // but the return is included here for good measure and readability
    if (deal.dealID !== dealID) {
      this.stopListening(deal.dealID);
      this.loadDeal(dealID);
      return;
    }

    // DISABLE DEEPLINKING BECAUSE IT IS BROKEN (React-Virtualized)
    return;

    // For activity deep links, we need to do 3 things in sequence:
    if (mode == 'contract' && sectionID != null) {
      //1. scroll down to target section
      const selector = `[data-sectionid="${sectionID}"]`;
      this.setState({ deepLinking: true });
      jump(selector, {
        duration: 1000,
        offset: -300,
        callback: () => {
          const { deal } = this.state;
          const section = deal ? deal.sections[sectionID] : null;

          if (section) {
            //2a. if section has open comments, reveal activity
            if (section.status == DealStatus.DISCUSS) this.toggleActivity(true, sectionID);
            //2b. if section has open changes, go to edit mode on that section
            else if (section.status == DealStatus.REVIEW) {
              //only go into edit mode if whole section is not deleted (i.e. redlining)
              //if section is deleted the approval/rejection happens at the section level
              if (!section.deleted) {
                //nothing to do here as deep linking is disabled due to virtual windowing
                //if/when we figure out how to re-enable, that code should go here :-)
              }
            }
          }

          this.setState({ deepLinking: false });

          //3. shave sectionID off of URL
          props.history.push(`/deals/${props.match.params.dealID}/contract`);
        },
      });
    }
  }

  async logEvents(deal, user, mode) {
    if (!deal || !user || !deal.currentDealUser) return;

    const { logging } = this.state;
    const du = deal.currentDealUser;

    // Log the first time each user views the contract
    // and only once they're viewing the actual contract (not overview)
    if (deal.creationEvent && !du.readEvent && !logging && mode === 'contract') {
      await this.setState({ logging: true });
      await Fire.addActivity(deal, user, DealAction.READ);
      await this.setState({ logging: false });
    }
  }

  accessCheck() {
    const { deal } = this.state;
    const { user } = this.props;

    //if we don't have enough info yet, return true (allow it to keep loading)
    if (!deal) return true;

    const du = deal.currentDealUser;

    //if looking at a template, use team membership to determine access (instead of membership on deal)
    if (deal.isTemplate) {
      //Team-based template access -- enable viewing if user is on template's team
      if (user && user.teams && deal.template.team && user.teams[deal.template.team]) {
        // console.log(`Template access allowed based on [${deal.template.team}] team membership`);
        //TODO: we can enable different Team roles, e.g.,
        //some members who can create deals from templates but can't edit the templates themselves
        //for now just enable editing if user is on team
        return true;
      }

      //legacy user-based access -- enable viewing if user is explicitly a member of the Template
      if (du != null) {
        // console.log(`Template access allowed based on user membership`);
        return true;
      }

      //if we get here, user doesn't have access to this template
      return false;
    }

    //for normal case (deal), use deal membership to determine access
    else {
      // Should be impossible (should get firebase access error before getting here) but just in case
      if (!du) return false;

      // Viewers can not access edit mode (e.g., template users who can only make deals from template but not edit)
      if (du.role == DealRole.VIEWER && this.mode == 'editor') return false;

      return true;
    }
  }

  //we keep a list in state of sectionIDs for which the ActivityView is visible
  //this ensures that we still show activity if user closes one by opening another
  //it also future-proofs us in case we want to have multiple ActivityViews visible concurrently
  toggleActivity(show, sectionID) {
    const showActivity = this.state.showActivity;
    const newShowActivity = [...showActivity];
    const idx = newShowActivity.indexOf(sectionID);
    if (show && idx < 0) {
      newShowActivity.push(sectionID);
    } else if (!show && idx > -1) {
      newShowActivity.splice(idx, 1);
    }
    this.setState({ showActivity: newShowActivity });
  }

  toggleHistory(sectionID) {
    const deal = this.state.deal;
    let versionHistory = null;
    if (deal && sectionID) versionHistory = deal.sections[sectionID];
    this.setState({ versionHistory });
  }

  onZoom(scale) {
    this.setState({ scale });
  }

  onPDFEditMode(pdfEditMode, pdfField = null) {
    if (this._isMounted) this.setState({ pdfEditMode, pdfField });
  }

  onPDFLoad(pdf) {
    if (this._isMounted) this.setState({ pdf });
  }

  get mode() {
    return _.get(this.props, 'match.params.mode', 'default');
  }

  get selectedVersion() {
    const { deal } = this.state;
    if (!deal) return null;
    const ordinal = _.get(this.props, 'match.params.sectionID', null);
    return deal.getVersion(ordinal);
  }

  get canUploadAttachment() {
    const { deal, showUploader, panel } = this.state;

    // only owners/editors can upload a new Attachment
    return (
      [DealRole.OWNER, DealRole.EDITOR].includes(_.get(deal, 'currentDealUser.role')) &&
      // don't re-show if already showing
      !showUploader &&
      // don't allow if versions/attachments panel is showing
      !['attachments', 'versions'].includes(panel) &&
      // if there are pending changes, no new versions
      !_.get(deal, 'pdfElements.length')
    );
  }

  get dealProps() {
    const { trackChanges, defaultRedline } = this.state;

    const selectedVersion = this.selectedVersion;
    const isTrackChanges = trackChanges === null ? defaultRedline : trackChanges;

    return {
      blockID: this.state.blockID,
      user: this.props.user,
      location: this.props.location,
      history: this.props.history,
      summary: this.state.summary,
      headers: this.state.headers,
      footers: this.state.footers,
      source: this.state.source,
      deal: this.state.deal,
      panel: this.state.panel,
      signed: this.state.signed,
      locked: this.state.locked,
      selectedVersion,
      // Any version other than current (latest) is readonly
      readonly: selectedVersion && selectedVersion !== _.get(this.state.deal, 'currentVersion'),
      template: this.state.template,
      toc: this.state.toc,
      mode: this.mode,
      scale: this.state.scale,
      activityMode: this.state.activityMode,
      activity: this.state.activity, //stats object
      showActivity: this.state.showActivity, //array of sectionIDs which should render activity
      showDealStatus: this.state.showDealStatus,
      notifyChanges: this.state.notifyChanges,
      trackChanges: isTrackChanges,
      settings: this.state.settings,
      tocKey: this.state.tocKey,
      variableIndex: this.variableIndex,
      versionHistory: this.state.versionHistory,
      toggleActivity: (show, sectionID) => this.toggleActivity(show, sectionID),
      toggleDealStatus: (showDealStatus) => this.setState({ showDealStatus }),
      toggleSettings: (settings) => this.setState({ settings }),
      toggleHistory: (sectionID) => this.toggleHistory(sectionID),
      toggleNotify: () => this.setState({ notifyChanges: !this.state.notifyChanges }),
      toggleTrack: () => {
        let toggleTracks = trackChanges;
        // Only check for the first toggle if default redline is true
        if (trackChanges === null && defaultRedline === true) {
          toggleTracks = true;
        }
        this.setState({ trackChanges: !toggleTracks });
      },
    };
  }

  onRequestInvitation() {
    this.setState({ readOnlyModalMode: 'request', showReadOnlyModal: true });
  }

  async handleCloseReadOnlyModal() {
    await this.setState({
      showReadOnlyModal: false,
      hasClosedReadOnlyModal: true,
    });
  }

  handleAttachmentUploaded(attachment) {
    const { panel } = this.state;
    const handler = _.get(this.refHeader, 'current.handlePanel');
    const attachmentType = _.get(attachment, 'attachmentType');

    this.setState({
      showUploader: false,
      droppedFile: null,
    });

    // If the user drops an Attachment straight onto the DealView, the Attachments panel will not be open
    // So we need to manually open it to provide some UI feedback of success
    if (attachmentType === ATTACHMENT_TYPE.STORAGE && !panel && typeof handler === 'function') {
      handler('attachments');
    }
  }

  async removeDealFromTrash(dealID) {
    try {
      await API.call('removeDealFromTrash', { dealID });
    } catch (error) {
      console.log(error);
      this.setState({ error: error });
    }

    this.setState({ deleted: false });
  }

  renderDealHeader() {
    const { user } = this.props;
    const { deal, pdfEditMode, pdfField, pdf } = this.state;

    if (this.mode === 'readonly' && !this.accessCheck()) {
      return <ReadOnlyDealHeader deal={deal} onClickRequest={this.onRequestInvitation} />;
    }

    if (!user || !deal || deal.isTemplate || ['editor', 'draft'].indexOf(this.mode) !== -1) {
      return;
    }

    return (
      <DealHeader
        {...this.props}
        {...this.dealProps}
        ref={this.refHeader}
        onZoom={this.onZoom}
        onPDFEditMode={this.onPDFEditMode}
        pdfEditMode={pdfEditMode}
        pdfField={pdfField}
        pdf={pdf}
        pdfView={this.refPDF}
        refTimevine={this.refTimevine}
        onPanelChange={(panel) => this.setState({ panel })}
        onToggleBundle={(showBundle) => this.setState({ showBundle })}
        onDeleteDeal={this.handleDeleteDeal}
      />
    );
  }

  render() {
    const { user, history, location, match } = this.props;
    const {
      deal,
      noAccess,
      loading,
      showReadOnlyModal,
      readOnlyModalMode,
      droppedFile,
      showUploader,
      showBundle,
      error,
      deleted,
      dealDeleted,
    } = this.state;
    const dealID = _.get(match, 'params.dealID');
    const readonly = _.get(deal, 'readonly', false) && !deal.isTemplate;

    const dealUsers = _.get(deal, 'users');
    const isOwner = user && _.get(_.find(dealUsers, { uid: user.id }), 'role') === DealRole.OWNER;
    const isObserverOwner = user?.teamMemberships?.[_.get(deal, 'info.sourceTeam')]?.observerRole === 'owner';
    const canRestore = isOwner || isObserverOwner;

    if (loading) {
      return (
        <div className="fullscreen-preloader" data-cy="dealview-loader">
          <Loader size="large" />
        </div>
      );
    }

    if (error) return <ErrorView error={error} user={user}></ErrorView>;

    if (noAccess) {
      this.props.og({ title: `Outlaw - No Access!` });
      return (
        <main className="access-page">
          <div className="col1">
            <div className="col1-inner">
              <NoDealAccess user={user} history={history} match={match} dealID={dealID} />
            </div>
            <PromoFooter />
          </div>
        </main>
      );
    }

    if (!dealDeleted && deleted) {
      return (
        <ModalConfirm
          backdrop="static"
          show={true}
          onHide={!isVine ? () => history.push('/dashboard/contracts') : undefined}
          onConfirm={canRestore ? () => this.removeDealFromTrash(dealID) : undefined}
          confirmText={`Restore ${Dt}`}
          cancelText={`Go to ${Dt}s Dashboard`}
          title={`${Dt} unavailable`}
          showConfirmText={canRestore}
          dmpStyle="primary"
          body={`'${deal.name}' has been moved to trash and is no longer accessible.`}
          info={
            canRestore
              ? `If you need access to this ${dt}, you can remove it from trash.`
              : `If you believe this is an error, please contact the ${dt} owner for assistance.`
          }
        />
      );
    }

    if (dealDeleted) {
      return (
        <ModalConfirm
          show={true}
          onHide={() => {
            this.setState({ dealDeleted: false });
          }}
          onConfirm={this.confirmDeleteDeal}
          confirmText="Move to Trash"
          cancelText="Cancel"
          title="Confirm move to trash"
          body={`Are you sure you want to move '${deal.info.name}' to trash?`}
          info={`This ${dt} will be permenently deleted after 30 days.`}
        />
      );
    }

    if (!readonly && (!deal || !user)) return null;

    this.props.og({ title: `Outlaw - ${deal.name}` });

    const targetVersion = this.selectedVersion;
    const className = cx(
      'in-app-deal',
      {
        'outer-paper-layout':
          this.mode !== 'timeline' && targetVersion.isOriginal && !['editor', 'draft'].includes(this.mode),
      },
      { 'show-bundle': deal.isBundle && showBundle },
      { dotvine: isVine }
    );

    return (
      <ErrorView user={user}>
        <Dropper
          acceptedTypes={_.map(STORAGE_TYPES, 'mime').join(',')}
          onDrop={(droppedFile) => this.setState({ droppedFile, showUploader: true })}
          title="Drop new associated file here"
          subtitle={'Accepted: ' + _.map(STORAGE_TYPES, 'display').join(', ')}
          suppress={!this.canUploadAttachment}
        >
          <main>
            {user.isAdmin && <MegaView deal={deal} source={this.state.source} container={this} />}

            {this.renderDealHeader()}

            <div className={className}>{this.renderMode(this.mode)}</div>

            {!deal.isTemplate && <DealFooter />}

            {showReadOnlyModal && (
              <ReadOnlyDealModal
                dealID={deal.dealID}
                history={history}
                location={location}
                mode={readOnlyModalMode}
                onHide={this.handleCloseReadOnlyModal}
                user={user}
              />
            )}

            {droppedFile && (
              <AttachmentUploader
                deal={deal}
                user={user}
                show={showUploader}
                history={history}
                droppedFile={droppedFile}
                onClose={this.handleAttachmentUploaded}
              />
            )}
          </main>
        </Dropper>
      </ErrorView>
    );
  }
  renderMode(mode) {
    const { pdfEditMode, pdfField, scale, panel } = this.state;

    switch (mode) {
      // template authoring modes
      case 'editor':
      case 'draft':
        return <TemplateEditor {...this.dealProps} {...this.props} ref={this.refDraft} />;
      // normal deal modes
      case 'source':
      case 'contract':
      case 'v':
        if (!this.selectedVersion.isOriginal) {
          return (
            <PDFView
              {...this.dealProps}
              onPDFEditMode={this.onPDFEditMode}
              pdfEditMode={pdfEditMode}
              pdfField={pdfField}
              onLoad={this.onPDFLoad}
              ref={this.refPDF}
              panel={panel}
            />
          );
        } else {
          return (
            <SourceViewVirtual
              {...this.dealProps}
              container={this}
              onAiBlockSelected={(panel, blockID) => this.setState({ panel, blockID })}
            />
          );
        }
      // mainly for testing; not linked to anywhere in UI
      case 'pdf':
        return <PDFView {...this.dealProps} scale={scale} onLoad={this.onPDFLoad} />;
      case 'readonly':
        return <SourceViewVirtual {...this.dealProps} container={this} readonly editable={false} />;
      case 'casedata':
        return <DocRequest {...this.dealProps} container={this} />;
      case 'timeline':
        return <TimevineOutlaw ref={this.refTimevine} deal={this.dealProps.deal} />;
      case 'eventdata':
        return <TimelineEventEditor deal={this.dealProps.deal} user={this.dealProps.user} />;
      case 'default':
      case 'overview':
      case null:
      case undefined:
      default:
        return <SummaryView {...this.dealProps} container={this} />;
    }
  }
}

export default DealView;

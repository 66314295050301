import {
  assign,
  filter,
  find,
  findIndex,
  forEach,
  get,
  includes,
  isNil,
  keys,
  map,
  pick,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash';

import DealAction from '../enums/DealAction';
import DealRole from '../enums/DealRole';
import DealStatus from '../enums/DealStatus';
import { FILEVINE_SERVICE } from '../enums/IntegrationServices';
import InviteStatus from '../enums/InviteStatus';
import SectionType, { HEADER_FOOTER_CONFIG, HEADER_FOOTER_CONFIG_OPTIONS } from '../enums/SectionType';
import { DateFormatter } from '../utils/DateTime';
import { discoverOrder } from '../utils/OrderFormatter';
import ActivityLog from './ActivityLog';
import Appendix from './Appendix';
import Attachment, { ATTACHMENT_TYPE } from './Attachment';
import Branding from './Branding';
import Caption from './Caption';
import DealConnection from './DealConnection';
import DealInfo from './DealInfo';
import { DEFAULT_FOOTNOTE_CONFIG } from './DealStyle';
import DealUser from './DealUser';
import DealVersion, { ORIGINAL_KEY } from './DealVersion';
import Header from './Header';
import Item from './Item';
import List from './List';
import Model from './Model';
import Party from './Party';
import Payment from './Payment';
import { ReadyCheckStatus } from './ReadyCheck';
import Section from './Section';
import SectionColumns from './SectionColumns';
import Signature from './Signature';
import Source from './Source';
import StyleFactory from './StyleFactory';
import TableColumn from './TableColumn';
import Template from './Template';
import { THEME_FONTS } from './TypeStyle';
import Variable, { ConditionalValueTypes, EXTERNAL_TYPES, ValueType, VariableType } from './Variable';

const { NO_PAGES } = HEADER_FOOTER_CONFIG_OPTIONS;

// "preventTransfer" prevents specific behavior from being applied on a deal created from a template.
export const BEHAVIOR = {
  autoGuest: { key: 'autoGuest', title: 'Auto Guest', defaultValue: false },
  commenting: { key: 'commenting', title: 'Commenting', defaultValue: true },
  guestSigning: {
    key: 'guestSigning',
    title: 'Guest Signing',
    defaultValue: true,
  },
  isPublic: {
    key: 'isPublic',
    title: 'Public',
    defaultValue: false,
    preventTransfer: true,
  },
  letterheadType: {
    key: 'letterheadType',
    title: 'Letterhead Type',
    defaultValue: 'default',
  },
  readonly: { key: 'readonly', title: 'Read Only', defaultValue: false },
  showLetterhead: {
    key: 'showLetterhead',
    title: 'Show Letterhead',
    defaultValue: true,
  },
  showTitle: { key: 'showTitle', title: 'Show Title', defaultValue: true },
  theme: { key: 'theme', title: 'Theme', defaultValue: null },
  workflow: { key: 'workflow', title: 'Workflow', defaultValue: null },
  exportAttachments: { key: 'exportAttachments', title: 'File attachments', defaultValue: false },
};

export const PDF_CHAR_SETS = {
  latin: { key: 'latin', displayName: 'Latin (default)' },
  korean: { key: 'korean', displayName: 'Korean' },
  japanese: { key: 'japanese', displayName: 'Japanese' },
};
export const PDF_CHAR_SETS_DEFAULT = PDF_CHAR_SETS.latin.key;

// List of aspects of a Deal that can accumulate data over time via usage,
// sort of like a subset of properties
// Currently only used in TestAPI.resetDeal for choosing which things to keep
export const ASPECTS = {
  CONTRACT: 'contract',
  OVERVIEW: 'overview',
  USERS: 'users',
  VARIABLES: 'variables',
  SIGNATURES: 'signatures',
  VERSIONS: 'versions',
  ACTIVITY: 'activity',
  PAYMENTS: 'payments',
  SCOPE: 'scope',
};

export const DEAL_TYPE = {
  // Native deals are created from Outlaw templates and signed end-to-end on Outlaw platform
  NATIVE: 'native',
  // Ingested deals are imported one-off from a docx, generally used for internal redlining/review
  INGESTED: 'ingested',
  // Bespoke deals are actually native to Outlaw but are created from scratch (not template-driven)
  BESPOKE: 'bespoke',
  // External deals have already been executed off-platform (e.g., legacy migration),
  // but are stored/indexed on Outlaw (with metadata imported via CSV) for repository and reporting
  EXTERNAL: 'external',
  // Externally managed (but uploaded and stored) docx files, aka "third party paper"
  EXTERNAL_WORD: 'externalWord',
};

export default class Deal extends ActivityLog {
  info = {};
  payments = [];
  checks = [];
  variables = {};
  unusedVariables = {};
  attachments = {};
  versions = {};
  currentOCR;
  connections = [];
  deleted = null;
  // For child Deals that are part of a Bundle, this will be set at creation time
  // To link this Deal to a LINKED appendix section in the parent
  parentDealID = null;

  // When a Deal is loaded that's part of a Bundle (whether parent or children), DealView loads the Bundle subsequently
  // And attaches it for use in child components (VariableView etc)
  bundle = null;

  // Similar to Bundle, theme (DealStyle) is loaded subsequently and attached immediately after instantiation
  _style = null;

  static createSection(json, deal) {
    switch (json.sectiontype) {
      case SectionType.SOURCE:
        return new Source(json, deal);
      case SectionType.HEADER:
        return new Header(json, deal);
      case SectionType.APPENDIX:
      case SectionType.LINKED:
        return new Appendix(json, deal);
      case SectionType.LIST:
        return new List(json, deal);
      case SectionType.SIGNATURE:
        return new Signature(json, deal);
      case SectionType.ITEM:
        return new Item(json, deal);
      case SectionType.CAPTION:
        return new Caption(json, deal);
      default:
        return new Section(json, deal);
    }
  }

  constructor(json) {
    super(json.activity);
    this.raw = json;

    this.dealID = json.dealID;

    (this.sections = {}), (this.users = []), (this.parties = []);

    this.info = new DealInfo(json.info);
    this.preview = json.preview ? true : false;

    // Set behaviors, w/ default if not specified
    Object.values(BEHAVIOR).forEach((behavior) => {
      this[behavior.key] = !isNil(json[behavior.key]) ? json[behavior.key] : behavior.defaultValue;
    });

    if (json.pdfCharSet && PDF_CHAR_SETS[json.pdfCharSet]) this.pdfCharSet = json.pdfCharSet;

    if (json.template) this.template = new Template(json.template, json.dealID);

    if (json.deleted) this.deleted = json.deleted;

    // Note: this needs to come after Template because DealConnection.constructor depends on Deal.isTemplate
    forEach(json.connections, (connectionJSON) => {
      this.connections.push(new DealConnection(connectionJSON, this));
    });
    // Copy connected status onto DealInfo so that it knows when to update status updates (see Fire.updateDealInfo)
    if (this.isConnected) this.info.connected = true;

    this.dealType = json.dealType || DEAL_TYPE.NATIVE;

    // This is used to store the current version OCR content, only use on the BE/Watcher.
    if (json.currentOCR) this.currentOCR = json.currentOCR;

    // Link up parentDealID for children in a Deal Bundle
    if (json.parentDealID) this.parentDealID = json.parentDealID;

    //Get the footnote config from the template or deal, otherwise utilize the default setup.
    this.footnoteConfig =
      this.template && this.template.footnoteConfig
        ? this.template.footnoteConfig
        : json.footnoteConfig
        ? json.footnoteConfig
        : DEFAULT_FOOTNOTE_CONFIG;

    //load in typed objects for users
    if (json.users) {
      map(json.users, (user, key) => {
        // To ensure data privacy, we're stripping tags from other users so that they will never be accessible
        // Note this is only done on client-side -- server will have no Model.user and will therefore not strip tags (important for indexing!)
        // https://trello.com/c/tv0t5S4F
        if (Model.user != null && Model.user.id != user.uid && user.tags) user.tags = null;

        this.users.push(new DealUser(user, this, key));
      });
    }

    //load in typed objects for payments
    if (json.payments) {
      let payments = [];
      forEach(json.payments, (pmt) => payments.push(new Payment(pmt, this)));
      this.payments = sortBy(payments, 'order');
    }

    // Load in Attachments and Versions if any are found
    // If this is an EXTERNAL deal and there are no Attachments/Versions defined,
    // there is a legacy storage path structure for the single attached PDF
    if (json.attachments) {
      forEach(json.attachments, (att, key) => (this.attachments[key] = new Attachment(att, this, key)));
    }

    // Normal case, for native/bespoke deals
    // Similar to Section.versions, we want to create 1 dynamically on the fly,
    // so that there's always an "original" to show
    if (!this.isExternal) {
      this.versions[ORIGINAL_KEY] = DealVersion.Original(this);
    }
    // Now, if we have *additional* versions stored, which can either be a ntaive/bespoke deal turned 3pp
    // Or an external deal from the start, import version data
    if (json.versions) {
      forEach(json.versions, (ver, key) => (this.versions[key] = new DealVersion(ver, this, key)));
    }

    this.lensChecks = json.lensChecks ? json.lensChecks : null;

    // Load in style (number format etc) data if it exists; otherwise use default
    // Note -- important for this to happen prior to loading in sections
    // So that APPENDIX sections can reference parent Deal.style if not defined
    this.theme = this.isTemplate ? this.template.theme || null : json.theme || null;

    // ReadyChecks are a special type of Activity with extra nested data
    this.checks = filter(this.activity, { action: DealAction.READY_CHECK });

    this.extracted = json.extracted ? json.extracted : null;

    this.documentAI = json.documentAI ? json.documentAI : false;

    this.hasLenses = json.hasLenses ? json.hasLenses : false;
    // 1. loop through and get just the source sections
    // populate the root property whenever we find it
    forEach(json.sections, (sectionJSON, sectionID) => {
      // TBD what circumstance results in a section without a type (instead of fully deleted section),
      // but it's happened and it breaks a ton of stuff, so to be safe, simply ignore this data
      if (!sectionJSON.sectiontype) return;

      const sec = Deal.createSection(sectionJSON, this);

      this.sections[sectionID] = sec;

      if (sec.isRoot) this.root = sec;
    });

    //load in variables from section content, then merge with stored values
    forEach(this.sections, this.findVariables.bind(this));
    forEach(json.variables, (variableJSON, key) => {
      if (!variableJSON) return;

      const storedProps = pick(variableJSON, [
        'type',
        'prompt',
        'value',
        'displayName',
        'pluralName',
        'valueType',
        'assigned',
        'connectType',
        'externalType',
        'externalSelector',
        'autoPull',
        'autoPush',
        'writable',
        'multiline',
        'showTotalRow',
        'totalRowLabel',
        'multilineValueOptions',
        'multilineValueLabels',
        'externalObjectID',
        'projectLinkFieldType',

        //Calculated
        'formula',
        'decimals',

        'variants',
        'extract',
        'extractType',
        'extractionInstructions',
        'seperator',
        'redactionExtent',
        'redactionMethod',
        'redactionValue',
        'language',
      ]);

      let target = this.variables[key];

      // If this Variable defined in json isn't actually used in any Section content, it won't exist yet in Deal.variables
      // So we need to create it here and add to object store
      // (This is fine because Variables can be used to store additional metadata or external data beyond what appears in Sections)
      if (!target) {
        //load into deal anyway, e.g., for editing
        target = new Variable({
          type: storedProps.type,
          valueType: storedProps.valueType,
          name: key,
          deal: this,
        });
        this.variables[key] = target;
        this.unusedVariables[key] = target;
      }

      if (storedProps.variants) {
        storedProps.variants = storedProps.variants.split('|');
      }

      // Finally, assign json values to the Variable
      assign(target, storedProps);

      // Special handling for advanced column definitions, so that they come in as typed models
      if (variableJSON.columns) {
        target._columns = map(variableJSON.columns, (colJSON) => new TableColumn(colJSON, target));
      }
    });

    //loop through again and hook up parent/child and source parent/child relationships
    forEach(this.sections, (sec) => {
      if (sec.parentid != null) {
        const parent = this.sections[sec.parentid];
        if (parent) {
          //hook up parent/child relationships:
          sec.parent = parent;
          parent.children.push(sec);
        }
      }

      if (sec.sourceparentid != null) {
        const srcParent = this.sections[sec.sourceparentid];
        if (srcParent) {
          //hook up source parent/child relationships
          sec.sourceParent = srcParent;
          srcParent.sourceChildren.push(sec);
        }
      }
      //legacy data -- SOURCE sections with no sourceparentid
      else if (sec.sectiontype == 'SOURCE') {
        // console.log('No source parent for section ' + key + ' found; assigning to ROOT');
        sec.sourceParent = this.root;
        sec.sourceparentid = this.root.id;
        this.root.sourceChildren.push(sec);
      }
    });

    // Load in parties. Note this needs to be done after sections and variables are loaded
    // Parties can be explicitly managed/stored on the Deal in addition to being discovered in Section content
    // So we want both -- which we've already found/merged in the variables list
    const parties = [];
    const partyIDs = [];
    const partyVars = filter(this.variables, { type: VariableType.PARTY });
    forEach(partyVars, (v) => {
      const varName = v.name.toString();
      const partyID = varName.split('.')[0];
      if (partyIDs.indexOf(partyID) < 0) {
        partyIDs.push(partyID);
        // Variable for root party ID (e.g., "@Company") may not be present
        // e.g., if only a property is referenced (e.g., @Company.legalName)
        const realParty = this.variables[partyID];
        if (realParty) {
          parties.push(
            new Party(
              {
                partyID,
                partyName: realParty.displayName || realParty.name,
                pluralName: realParty.pluralName || null,
              },
              this
            )
          );
        } else {
          parties.push(
            new Party(
              {
                partyID,
                partyName: partyID,
              },
              this
            )
          );
        }
      }
    });
    this.parties = parties;

    //common sorting function to sort based on sourceorder (which may be a number string)
    const sorter = (a, b) => {
      if (!isNaN(a.sourceorder) && !isNaN(b.sourceorder)) {
        return parseFloat(a.sourceorder) - parseFloat(b.sourceorder);
      } else {
        return a.sourceorder > b.sourceorder ? 1 : -1;
      }
    };

    //sort source, then build ordered source array and TOC for later use
    forEach(this.sections, (sec) => (sec.sourceChildren = sec.sourceChildren.sort(sorter)));

    const flatSource = this.buildSource();

    //4. sort summary (which includes refs to source sections) then build for later use
    forEach(this.sections, (sec) => {
      if (sec.sectiontype == 'SUMMARY' && sec.indentLevel == 1)
        sec.children = sortBy(sec.children, (src) => flatSource.indexOf(src));
      else sec.children = sortBy(sec.children, 'order');
    });
    if (this.root != null) this.root.sourceChildren = this.root.sourceChildren.sort(sorter);

    // Regardless of theme, we need to populate a default style property here in the constructor,
    // so that any getters that use it (eg Source.numberFormat) don't fail
    // The correct theme will then get loaded in and assigned subsequently in Fire.getDeal
    this.style = StyleFactory.create('default', null, json.style);
  }

  can(uid, action) {
    //upgraded from using locked to signed, but this should probably still go away
    //so that each action is fully case specific
    if (this.signed && !this.isExternal) return false;
    //readonly users (e.g., public deals) can do nothing
    if (!uid) return false;

    const du = find(this.users, { uid });
    if (!du) return false;

    switch (action) {
      //all users can comment
      case 'comment':
        return [DealRole.VIEWER, DealRole.PROPOSER, DealRole.EDITOR, DealRole.OWNER].includes(du.role);
      case 'edit':
      case 'share':
      case 'fillVariables':
        //added to maintain former logic but should eventually be removed
        if (this.locked) return false;
        return [DealRole.EDITOR, DealRole.OWNER].includes(du.role);
      case 'admin':
        //added to maintain former logic but should eventually be removed
        if (this.locked) return false;
        return [DealRole.OWNER].includes(du.role);
      default:
        return false;
    }
  }

  get providers() {
    const providers = [];
    forEach(this.variables, (v) => {
      if (v.type === VariableType.CONNECTED && v.externalType === EXTERNAL_TYPES.COLLECTION && v.val) {
        const hasProviders = includes(keys(v.val[0]), 'provider');
        const providersArray = hasProviders ? map(v.val, 'provider') : [];
        providers.push(...providersArray);
      }
    });
    return providers;
  }

  get currentDealUser() {
    if (Model.user != null) {
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].uid == Model.user.id) return this.users[i];
      }
    }
    return null;
  }

  // LONG overdue getter to reduce the need to check for role in various components
  get isOwner() {
    return get(this, 'currentDealUser.role') === DealRole.OWNER;
  }

  get status() {
    if (this.template) return DealStatus.TEMPLATE;
    if (this.signed) return DealStatus.SIGNED;
    if (this.signing) return DealStatus.SIGNING;

    //if not signed or signing, we need to derive status from sections
    const stats = [];

    //look for TODOs in both Overview and Contract
    //this needs cleanup!
    if (this.root) forEach(this.root.children, (s) => stats.push(s.status));
    forEach(this.sections, (s) => {
      // Make sure that we verify for all sections that can contain variables (that needs to be filled)
      if (
        [SectionType.SIGNATURE, SectionType.SOURCE, SectionType.APPENDIX, SectionType.LIST, SectionType.ITEM].includes(
          s.sectiontype
        ) &&
        s.passesConditions
      )
        stats.push(s.status);
    });

    //if there is ANYTHING left ToDo, show ToDo
    if (filter(stats, DealStatus.TODO).length > 0) return DealStatus.TODO;

    //a deal that doesn't require signing is automatically complete as long as there's nothing left to do
    if (!this.requiresSigning) return DealStatus.COMPLETE;

    //if nothing left to ToDo but deal has not been shared yet (and requires signing), show as DRAFT
    if (!this.shared && this.needsSharing) return DealStatus.DRAFT;

    //otherwise, all key info is present so we're somewhere in negotiations
    //return draft status as fallback
    return DealStatus.REVIEW;
  }

  get style() {
    return this._style;
  }

  // When DealStyle is set (eg from either custom team theme or from built-in Outlaw default options)
  // We also need to propagate it down to child APPENDIX sections,
  // which can have their own custom data that overrides it
  // This cannot be incorporated directly into Fire.getDeal for performance reasons,
  // but happens *immediately* after instantiation (see DealView)
  set style(s) {
    this._style = s;

    const appendices = filter(this.sections, (s) => {
      return [SectionType.APPENDIX, SectionType.SIGNATURE].includes(s.sectiontype);
    });
    forEach(appendices, (appendix) => {
      appendix.style = StyleFactory.create(this.theme, this.style.json, appendix.raw.style);
    });
  }

  get shared() {
    //a deal has been shared if there are more than 1 users whose can view the deal
    const users = filter(this.users, ({ inviteStatus }) => InviteStatus.canView(inviteStatus));
    return users.length > 1;
  }

  get needsSharing() {
    const users = filter(this.users, ({ inviteStatus }) => InviteStatus.canView(inviteStatus));

    if (users.length < this.minimumSigners) return true;
    else return false;
  }

  get minimumSigners() {
    const sections = filter(this.sections, { sectiontype: SectionType.SIGNATURE, passesConditions: true });
    let signatories = [];

    forEach(sections, (section) => signatories.push(...section.signatories));

    return uniq(signatories).length;
  }

  get signing() {
    if (this.signed || !this.requiresSigning) return false;

    // If the stored status is already in signing, use that
    if (this.info.status === DealStatus.SIGNING.data) return true;

    //note, with updated (post June 2018) signing logic, this will return false if a user is partially but not fully signed
    //but this is intentional so that the deal status doesn't change while the user is in the middle of signing
    //this case has been specially accounted for below in Deal.locked()
    return filter(this.users, { signed: true }).length > 0;
  }

  get isProcessingAI() {
    const loadingExtracts = this.documentAI && !this.extracted?.pages && !this.extracted?.error;
    const loadingLenses = this.hasLenses && !this.lensChecks;

    return loadingExtracts || loadingLenses;
  }

  get hasVinnie() {
    const blocks = _.filter(this.sections, (sec) => _.get(sec, 'aiPrompt.engine.key') === 'vinnie');
    return blocks.length > 0;
  }

  get signed() {
    // First, if the stored status is signed, use that
    if (this.info.status === DealStatus.SIGNED.data) return true;

    // Use legacy logic (pre June 2018) if there is any signature data attached to users
    const legacy = filter(this.users, 'sig').length > 0;

    // Legacy logic: a Deal is signed if all parties have at least 1 user and all assigned users have sig data present
    if (legacy) {
      const signedParties = [];
      this.parties.map((party) => {
        const partyUsers = this.getUsersByParty(party.partyID);
        if (partyUsers.length > 0 && filter(partyUsers, 'sig').length == partyUsers.length) signedParties.push(party);
      });

      return this.parties.length > 0 && signedParties.length == this.parties.length;
    }

    // Updated logic: a Deal is signed if all signature sections are signed
    const sections = filter(this.sections, { sectiontype: SectionType.SIGNATURE, passesConditions: true });
    if (sections.length == 0) return false;
    return sections.length == filter(sections, { signed: true }).length;
  }

  // Quick lookup to see if ANY party has more than 1 signer
  // (if so there are minor differences in how Signature blocks are rendered/spaced)
  get multipleSigners() {
    return !!find(this.parties, (p) => p.users.length > 1);
  }

  get signedDate() {
    if (!this.signed) return null;

    const signActivities = filter(this.activity, { action: DealAction.SIGN });
    const sortedSignActivities = sortBy(signActivities, 'date').reverse();

    return get(sortedSignActivities, '[0].date', null);
  }

  // Deals can be created in various ways, so this provides a unified way to lookup the original means of creation
  get creationEvent() {
    let creationEvent = find(this.activity, (activity) =>
      [DealAction.CREATE, DealAction.COPY, DealAction.WEBHOOK].includes(activity.action)
    );

    // Not found, it's probably a Batch one or an old one.
    if (!creationEvent) {
      creationEvent = sortBy(this.activity, 'date')[0];
    }
    return creationEvent;
  }

  get created() {
    // New deals will have a created date, else fall back to activity data
    // Cannot return a unix timestamp here, needs to be a date
    return this.info.created ? new Date(parseInt(this.info.created)) : get(this, 'creationEvent.date', null);
  }

  get readyCheck() {
    return find(this.checks, { status: ReadyCheckStatus.OPEN });
  }

  get locked() {
    // EXTERNAL deals are implicitly read-only because they're PDFs
    // so we don't need to ever lock them; this way we can still freely manage sharing, metadata etc
    if (this.isExternal) return false;

    if (!get(this.workflow, 'isOutlaw')) {
      return get(this.currentVersion, 'step.locked');
    }

    if (this.signed) return true;

    //deal is locked if a ready check is in progress
    if (this.readyCheck != null) return true;

    //use legacy logic (pre June 2018) if there is any signature data attached to users
    const legacy = filter(this.users, 'sig').length > 0;

    //legacy logic: a deal is locked (no more edits) any user has signed
    if (legacy) return this.signing;
    //updated logic: a deal is locked if there is ANY (even partial) sig data
    //e.g., if a user has signed one of multiple required places
    return (
      filter(
        this.sections,
        (s) => s.sectiontype == SectionType.SIGNATURE && s.sigs != null && Object.keys(s.sigs).length > 0
      ).length > 0
    );
  }

  get startedSigning() {
    return filter(this.users, { startedSigning: true }).length > 0;
  }

  get owners() {
    return filter(this.users, { role: 'owner' });
  }

  get branding() {
    return new Branding(this.info);
  }

  get name() {
    return this.info.name;
  }

  get team() {
    if (this.isTemplate) {
      return this.template.team || '';
    } else {
      const src = this.info.sourceTemplateKey || '';
      return src.split(':')[0];
    }
  }

  get counterparty() {
    const me = this.currentDealUser;
    if (!me) return null;
    const otherParties = filter(this.parties, (p) => p.partyID != me.partyID);

    //if we find a counterparty return name of first user in that party
    if (otherParties.length > 0) {
      const counterPartyUsers = this.getUsersByParty(otherParties[0].partyID);
      return counterPartyUsers.length == 0 ? null : counterPartyUsers[0].displayName;
    }
    //if not, there may be other users in current party
    else {
      const otherUsers = filter(this.users, (u) => u != me && u.partyID != null);
      if (otherUsers.length > 0) return otherUsers[0].displayName;
      else return null;
    }
  }

  findVariables(section) {
    let m = null,
      reg = /\[([!@#$%*+^])([a-zA-Z][\w\d\s.-]*)\]/gi;

    let coreVars = this.raw.variables || {};
    //search both section body and title for variable references
    //now using latest version (instead of original) to ensure proper assessment of whether vars are necessary to be filled!
    const v = section.currentVersion;
    const index = [v.title.getPlainText() || '', v.body.getPlainText() || ''].join('|');

    while ((m = reg.exec(index))) {
      const [, type, name] = m;
      const v = this.variables[name] || new Variable({ type, name, deal: this });

      const [rootName, prop] = name.split('.');
      //apply the raw parent variable value to the spelled instance. (this must be done while we cylce though section variables)
      if (prop === 'spelled') {
        //only set the value if the variable exists in storage (not inferred)
        if (coreVars[rootName]) {
          v.value = coreVars[rootName].value;
        }
      }
      //store variables references at deal level
      this.variables[name] = v;
      //and also simple variable references at section level
      section.variables[name] = v;

      // If the var name has a . in it, it's either a derived var like [#Fees.spelled] or a party property like [@Company.legalName]
      // Either way, make sure the core var is also registered as being present on both the Deal and the Section

      // There is one important exception, which is (derived) table column (aggregate) variables
      // These are read-only and will only be accessible if the core table variable is explicitly defined elsewhere,
      // so we do NOT want to register the root (table) variable as being present on this section
      if (prop && get(coreVars, `${rootName}.valueType`) !== ValueType.TABLE) {
        const rootVar = this.variables[rootName] || new Variable({ type, name: rootName, deal: this });
        this.variables[rootName] = rootVar;
        section.variables[rootName] = rootVar;
      }
    }
  }

  // This is usually the final filtering function immediately prior to rendering or other processing
  // And usually takes in the output of Deal.buildSource as its first parameter here
  // However, buildSource() has been updated to include dynamically generated SectionColumns in its output
  // So the updates here are needed to accommodate that structural change
  // This is necessary because in certain scenarios (e.g., search indexing),
  // we actually want to ignore ("unwrap") the columns and inspect the sections inside
  applyConditions(sections, unwrapColumns = true) {
    const filtered = [];
    forEach(sections, (sec) => {
      if (sec.sectiontype === SectionType.COLUMNS) {
        if (unwrapColumns) {
          forEach(sec.sections, (colSec) => {
            if (colSec.passesConditions) filtered.push(colSec);
          });
        } else {
          filtered.push(sec);
        }
      } else {
        if (sec.passesConditions) filtered.push(sec);
      }
    });
    return filtered;
  }

  get activeHeaders() {
    const headers = this.buildHeaders();
    return filter(headers, (header) => {
      return header.passesConditions && !!header.headerFooterConfigKey && header.headerFooterConfigKey !== NO_PAGES;
    });
  }

  get activeFooters() {
    const footers = this.buildFooters();
    return filter(footers, (footer) => {
      return footer.passesConditions && !!footer.headerFooterConfigKey && footer.headerFooterConfigKey !== NO_PAGES;
    });
  }

  getUserByID(uid) {
    const u = filter(this.users, (du) => du.uid == uid || du.key == uid);
    return u.length > 0 ? u[0] : null;
  }
  getUsersByParty(partyID) {
    return filter(this.users, { partyID });
  }
  getPartyByID(partyID) {
    const p = filter(this.parties, { partyID });
    return p.length > 0 ? p[0] : null;
  }
  getUserByEmail(email) {
    const u = filter(this.users, { email });
    return u.length > 0 ? u[0] : null;
  }

  //build flat list of just the source sections
  //includeAll will also include non-source types (APPENDIX, SIGNATURE etc)
  buildSource(includeAll, nestColumns = true) {
    const sections = [];
    let columnBuffer = null;

    const recurser = (parents) => {
      parents.map((p, idx) => {
        if (includeAll || [SectionType.SOURCE, SectionType.LIST].includes(p.sectiontype)) {
          // We want to "group" contiguous column sections into a single object
          // So that they can easily be rendered on the same row (Draft, Flow, PDF, DOCX)
          // So when we find a section that should be displayed in columns, collect it in buffer
          if (nestColumns && get(p, 'style.columns') === true) {
            if (!columnBuffer) {
              columnBuffer = new SectionColumns(this);
            }
            if (p.passesConditions) {
              columnBuffer.sections.push(p);
              // Give the section a reference back to this columns container too,
              // So that it knows its position (eg for styling/padding)
              p.columnContainer = columnBuffer;
              if (p.pageBreak) {
                columnBuffer.pageBreak = p.pageBreak;
              }
            }
          }
          // Now look ahead; if the next section is NOT in columns, or if we're at the end, flush the buffer
          if (columnBuffer && (idx + 1 === parents.length || !parents[idx + 1].style.columns)) {
            sections.push(columnBuffer);
            columnBuffer = null;
          }
          // In normal case (for sections not in columns or when nesting is off) just add to the master array
          else if (!columnBuffer) {
            sections.push(p);
          }
          // We don't need to recurse and "unwrap" Caption sections (which have 2 children)
          // Or List sections (which can have n children)
          // because they manage their own child rendering so the nesting is ok for them
          // And keyboard/focus traversal is handled specially in Draft (see TemplateEditor.moveFocus)
          if (![SectionType.CAPTION, SectionType.LIST].includes(p.sectiontype)) {
            recurser(p.sourceChildren);
          }
        }
      });
    };
    if (this.root != null) {
      recurser(this.root.sourceChildren);
    }
    return sections;
  }

  // Build flat list of just the non-source sections; note, this is only used in Draft
  // Flow implements different rendering logic inside OverviewSection and ContentSection
  // In order to render UI based on underlying nested doc structure
  buildSummary() {
    const arr = [];
    const recurser = (parents) => {
      parents.map((p) => {
        if (SectionType.src(p.sectiontype) && p.sectiontype !== SectionType.LIST) return;
        if ([SectionType.TEMPLATE_HEADER, SectionType.TEMPLATE_FOOTER].includes(p.sectiontype)) return;
        arr.push(p);
        recurser(p.children);
      });
    };
    if (this.root != null) recurser(this.root.children);
    return arr;
  }

  buildHeaders() {
    const arr = [];
    const recurser = (parents) => {
      parents.map((p) => {
        if (p.sectiontype === SectionType.TEMPLATE_HEADER) {
          arr.push(p);
          recurser(p.children);
        }
      });
    };
    if (this.root != null) recurser(this.root.children);
    return arr;
  }

  buildFooters() {
    const arr = [];
    const recurser = (parents) => {
      parents.map((p) => {
        if (p.sectiontype === SectionType.TEMPLATE_FOOTER) {
          arr.push(p);
          recurser(p.children);
        }
      });
    };
    if (this.root != null) recurser(this.root.children);
    return arr;
  }

  //build flat list only including the referenceable sections, i.e., those with a number
  buildTOC() {
    return this.buildSource(true).filter((s) => !s.hideOrder || s.sectiontype == 'APPENDIX');
  }

  buildActivityStats(sections) {
    const activity = { open: 0, all: 0, changes: 0 };

    const check = (sec, track) => {
      // Ignore sections that don't pass current conditional filters
      // Without this, open comments on a section that gets subsequently will still block a deal from signing!
      if (!sec.passesConditions) return;

      //don't count comments on deleted sections
      if (sec.comments.length > 0 && !(sec.deleted && sec.deletionApproved)) {
        activity.all += 1;
        if (sec.status != DealStatus.AGREED) activity.open += 1;
      }
      if (track) {
        //changes in content
        if (sec.currentVersion.hasChanges('body') || sec.currentVersion.hasChanges('title')) activity.changes += 1;
        //changes in deleted (but not confirmed) sections
        if (sec.deleted && !sec.deletionApproved) activity.changes += 1;
      }
    };
    forEach(sections, (section) => {
      if (section.sectiontype === SectionType.COLUMNS) {
        forEach(section.sections, (sec) => check(sec, true));
      } else if (section.isCaption) {
        forEach(section.sourceChildren, (sec) => check(sec, true));
      } else if (section.isList) {
        forEach(section.items, (sec) => check(sec, true));
      } else {
        check(section, true);
      }

      if (section.sectiontype == SectionType.APPENDIX && section.parent) {
        switch (section.parent.sectiontype) {
          case SectionType.SCOPE:
            const items = filter(section.parent.children, { sectiontype: SectionType.ITEM });
            items.map((item) => check(item, true));
            break;
          case SectionType.PAYMENT:
            const payments = this.payments;
            payments.map((pmt) => check(pmt));
            break;
          default:
            break;
        }
      }
    });

    return activity;
  }

  filterActivity({ filteredTypes, excludedUsers, sort, groupByDay }) {
    // Start with (copy of) deal-level activity list
    let activity = !this.activity.length ? [] : this.activity.slice(0, this.activity.length);

    // Merge in all Section activity
    const sectionsWithActivity = filter(this.sections, (s) => !!s.activity.length);
    forEach(sectionsWithActivity, (section) => activity.push(...section.activity));

    // Now filter according to specified types and excluded users
    // Note if no filteredTypes param is passed in, all will be included
    // Same goes for excludedUsers
    activity = filter(activity, ({ action, user }) => {
      return (
        (!filteredTypes || filteredTypes.indexOf(action) > -1) && (!excludedUsers || excludedUsers.indexOf(user) === -1)
      );
    });

    // Sort final list by date
    activity = sortBy(activity, 'date');
    if (sort === 'desc') activity = activity.reverse();

    // Group by day if specified
    if (groupByDay) {
      const days = {};
      forEach(activity, (action) => {
        const date = DateFormatter.date(action.date - DateFormatter.getOffset(new Date()));
        if (!days[date]) days[date] = [];
        days[date].push(action);
      });
      return days;
    }
    // Otherwise just return flat activity array
    else {
      return activity;
    }
  }

  //attempt to find a target section based on text input like 5-A-(ii)
  findSection(textNumber, isAppendix) {
    const pieces = textNumber.split(/[-.()]/);
    let fail = false,
      section = this.root;
    pieces.map((text) => {
      //some pieces may be empty strings based on the split; ignore
      if (!text) return;

      //if we've already gone as far as we can by hitting a failure point,
      //don't try to parse later pieces of the string
      if (fail) return;
      //here we'll have something like "5" or "iii" or "C"; no way to know which
      //use utils to attempt to convert number into an ordered index
      const idx = discoverOrder(text);
      if (idx > -1) {
        const candidates = isAppendix
          ? filter(this.sections, { sectiontype: SectionType.APPENDIX })
          : filter(section.sourceChildren, (s) => !s.hideOrder);
        if (candidates.length > idx) section = candidates[idx];
        else fail = true;
      } else {
        fail = true;
      }
    });

    return section == this.root ? null : section;
  }

  get requiresSigning() {
    return filter(this.sections, { sectiontype: SectionType.SIGNATURE, passesConditions: true }).length > 0;
  }

  get native() {
    return this.dealType === DEAL_TYPE.NATIVE;
  }

  get isExternal() {
    return [DEAL_TYPE.EXTERNAL, DEAL_TYPE.EXTERNAL_WORD].includes(this.dealType);
  }

  get currentVersion() {
    const versions = sortBy(this.versions, 'dateCreated').reverse();
    return versions.length > 0 ? versions[0] : null;
  }

  get originalVersion() {
    return this.versions[ORIGINAL_KEY] || null;
  }

  get hasVersions() {
    return keys(this.versions).length > 1 || this.isExternal;
  }

  get pendingPDFChanges() {
    return filter(this.pdfElements, { isFilled: true });
  }

  get fonts() {
    const sectionFonts = [];
    forEach(this.sections, (section) => {
      if (section.styleBody.raw.native) {
        sectionFonts.push(
          find(THEME_FONTS, (font) => {
            return font.key === section.styleBody.raw.native.font;
          })
        );
      }
      if (section.styleTitle.raw.native) {
        sectionFonts.push(
          find(THEME_FONTS, (font) => {
            return font.key === section.styleTitle.raw.native.font;
          })
        );
      }
    });
    return uniq([...this.style.fonts, ...sectionFonts]);
  }

  // Get a target DealVersion based on an (optional) version number
  // Note, ordinal can come from url params so it's a string, whereas Version.ordinal is a number
  // So we're using the "==" operator here becomes these are truthy, i.e., '3' == 3 :-)
  getVersion(ordinal) {
    let version;
    if (ordinal) {
      version = find(this.versions, (v) => v.ordinal == ordinal);
    }
    return version || this.currentVersion;
  }

  // If a non-native (usually INGESTED) deal is in Draft status,
  // it means it should only be editable in the Draft editor
  get inDraft() {
    return !this.native && get(this, 'info.status') === DealStatus.DRAFT.data;
  }

  get pageBreakConfig() {
    const orderedSections = this.buildSource(true);

    let pageBreakSections = _.filter(orderedSections, { pageBreak: true, isAppendix: false, isSignature: false });
    let appendixSections = _.filter(orderedSections, { isAppendix: true });
    let signatureSections = _.filter(orderedSections, { isSignature: true });

    pageBreakSections = _.map(pageBreakSections, (section, index) => {
      return { key: 'pageBreak', id: section.id, displayName: `page break ${index + 1}`, type: 'section' };
    });
    appendixSections = _.map(appendixSections, (section, index) => {
      return { key: 'pageBreak', id: section.id, displayName: `Appendix ${index + 1}`, type: 'appendix' };
    });
    signatureSections = _.map(signatureSections, (section, index) => {
      return { key: 'pageBreak', id: section.id, displayName: `Signature ${index + 1}`, type: 'signature' };
    });

    return [...pageBreakSections, ...appendixSections, ...signatureSections];
  }

  get headerFooterConfig() {
    //Set active or not active properties on each option for display
    return [...HEADER_FOOTER_CONFIG, ...this.pageBreakConfig];
  }

  get headerSections() {
    return filter(this.sections, { sectiontype: SectionType.TEMPLATE_HEADER, parentid: 'root' });
  }

  get footerSections() {
    return filter(this.sections, { sectiontype: SectionType.TEMPLATE_FOOTER, parentid: 'root' });
  }

  get timeline() {
    const timelineSections = filter(this.sections, { isTimeline: true });
    return timelineSections.length > 0 ? timelineSections[0] : null;
  }

  get hasTimeline() {
    return this.timeline !== null;
  }

  // This is a little confusing but was done this way to use our existing BEHAVIOR paradigm,
  // While also continuing to use the Deal.workflow getter that existed prior to custom workflows
  // 1. When deal is created via template, BEHAVIOR transfer will copy the (string) workflow property to the deal's json
  // 2. When json is loaded in Deal.constructor, BEHAVIOR transfer calls the setter below and actually stores the value at Deal.workflowKey
  // 3. Fire.getWorkflow accesses the Deal.workflowKey directly to load the correct Workflow instance and assign it directly to Deal._workflow
  // 4. Anytime Deal.workflow is then accessed, it correctly retrieves the fully typed Workflow instance (not the string)
  get workflow() {
    return this._workflow;
  }

  set workflow(workflowKey) {
    this.workflowKey = workflowKey;
  }

  get currentWorkflowStep() {
    const steps = get(this.workflow, 'steps', []);
    const key = get(this.info, 'status');
    if (!steps.length || !key) return null;
    return find(steps, { key }) || steps[0];
  }

  // INGESTED / BESPOKE / EXTERNAL deals all have a manual, explicitly set workflow
  // For now this is 1:1 with whether the Deal is NATIVE (i.e., none of the above)
  // This may expand as we get into more customized templating so a separate getter is appropriate
  get isWorkflowManual() {
    return !this.native || keys(this.versions).length > 1 || !this.workflow.isOutlaw;
  }

  // Helper getter to get an appropriate name of document type,
  // now that we have both Templates and Contracts being edited in Draft
  get documentType() {
    return this.template ? 'Template' : 'Contract';
  }

  // Helper getter to pull out the variables that can be used as conditionals
  get conditionals() {
    return filter(
      this.variables,
      (variable) => !variable.isDerived && ConditionalValueTypes.includes(variable.valueType)
    );
  }

  get isTemplate() {
    return !!this.template;
  }

  // If this is a template, see if there are child LINKED appendices as a different path will be required for creation
  // If this is a contract, see if there are other child linked deals or a linked parent
  get isBundle() {
    let links = [];
    if (this.isTemplate) {
      links = filter(this.sections, 'linkedTemplate');
    } else {
      // If this is actually a child in a doc bundle,
      // it will not have its own children but should still eval to true
      if (this.parentDealID) return true;
      links = filter(this.sections, 'linkedDealID');
    }
    return links.length > 0;
  }

  get bundleID() {
    return this.parentDealID || this.dealID;
  }

  get isBundleParent() {
    return this.isBundle && !this.isTemplate && this.bundleID === this.dealID;
  }

  get isConnected() {
    return this.connections.length > 0;
  }

  get hasTemplate() {
    const key = get(this, 'info.sourceTemplateKey', '');
    return key.split(':').length === 2;
  }

  get hasSectionVersions() {
    const contract = this.applyConditions(this.buildSource(true));
    return filter(contract, (section) => section.versions.length > 1).length > 0;
  }

  // Generate a list of variables that can be used for filtering and column display
  // Ensure that we're only looking at SIMPLE ones and no derived properties (e.g., ".spelled")
  // And disable TABLE and IMAGE vars
  get filterableVariables() {
    let vars = filter(this.variables, (variable) => {
      return (
        variable.type === VariableType.SIMPLE &&
        [ValueType.IMAGE, ValueType.TABLE].indexOf(variable.valueType) === -1 &&
        !variable.isDerived
      );
    });

    vars = sortBy(vars, ['displayName', 'name']);

    return vars;
  }

  get simpleVariables() {
    let vars = filter(this.variables, (variable) => variable.type === VariableType.SIMPLE && !variable.isDerived);
    vars = sortBy(vars, ['displayName', 'name']);
    return vars;
  }

  get syncedVariables() {
    let vars = {};
    forEach(this.variables, ({ baseVariable, service, autoPull, value }) => {
      if (service && (autoPull || isNil(value)) && !vars[baseVariable.name]) vars[baseVariable.name] = baseVariable;
    });
    return vars;
  }

  get syncedValues() {
    let vars = {};
    forEach(this.syncedVariables, ({ name, value }) => {
      vars[name] = value;
    });
    return vars;
  }

  get suggestVariables() {
    return filter(this.variables, (variable) => {
      let isValid = [VariableType.SIMPLE, VariableType.CALCULATED, VariableType.PARTY, VariableType.PROTECTED].includes(
        variable.type
      );
      if (variable.isProperty) isValid = false;
      return isValid;
    });
  }

  // Simple string representation of the suggestable variables stored on the Deal,
  // used for quick comparison to see if we need to update the VariableIndex as Deal data changes -- see DealView.loadDeal
  get suggestVariablesKey() {
    const variableNames = map(uniqBy(this.suggestVariables, 'name'), 'name');
    return variableNames.sort().join(',');
  }

  get attachedFiles() {
    return filter(this.attachments, (attachment) => {
      const { attachmentType } = attachment;
      if (attachmentType === ATTACHMENT_TYPE.STORAGE || attachmentType === ATTACHMENT_TYPE.VARIABLE) {
        return attachment;
      }
    });
  }

  get lists() {
    return filter(this.sections, { sectiontype: SectionType.LIST });
  }

  // Get a list of all distinct collection types referenced by connected variables
  // To be used as input into DealConnection.idFields,
  // so that we know which field keys need to be populated for API retrieval of connected vars
  // This could potentially be made more generic at some point (e.g. to pull disparate Salesforce objects)
  // But for now let's get the FV integration fully implemented and go from there ;-)
  get fvCollections() {
    const collectionFields = filter(this.variables, {
      connectType: FILEVINE_SERVICE.key,
      externalType: EXTERNAL_TYPES.COLLECTION_FIELD,
    });
    let collections = map(collectionFields, ({ externalSelector = '' }) => externalSelector.split('.')[0]);
    collections = uniq(collections);
    return collections;
  }
}

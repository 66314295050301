import FlexSearch from 'flexsearch';
import { forEach, isArray } from 'lodash';

export const USER_SEARCH_LIMIT = 500;

class UserIndex {
  index = null;

  constructor() {
    this.initIndex();
  }

  initIndex() {
    this.index = new FlexSearch.Document({
      tokenize: 'full',
      minLength: 3,
      language: 'en',
      depth: '2',
      document: {
        store: true,
        id: 'id',
        index: ['fullName', 'email'],
      },
    });
  }

  reset() {
    this.initIndex();
  }

  search(...args) {
    return this.index.search(...args);
  }

  getAll() {
    return Object.values(this.index.store);
  }

  add(users) {
    const entries = !isArray(users) ? [users] : users;

    forEach(
      entries,
      ({
        email,
        fullName,
        id,
        role,
        status,
        observer,
        isAdmin,
        title,
        inviteID,
        canCreateNewDoc,
        withoutRedline,
        observerRole,
      }) => {
        this.index.add({
          email,
          fullName,
          id,
          role,
          status,
          observer,
          isAdmin,
          title,
          inviteID,
          canCreateNewDoc,
          withoutRedline,
          observerRole,
        });
      }
    );
  }
}

export default UserIndex;

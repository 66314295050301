import React from 'react';

import PropTypes from 'prop-types';

import { ButtonIcon } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

const Numbering = ({ deal }) => {
  const { style } = deal;
  const { border, layout, type } = style;
  const lineNumberPadding = 29.6;
  //Calculate the position of numbers based on where the border is located, width of numbers (20px), and .1" away from either the left edge of content or the left border
  const marginLeft = border.BorderLeft?.enabled
    ? -(layout.PageMargin.left - border.BorderLeft.layout.left + lineNumberPadding)
    : -lineNumberPadding;
  //until we have paged documents in flow and ediotr we are using
  //1-10 as our default numbering to display in editor and flow.
  const numbers = 11;

  return (
    <div className="numbering" style={{ marginLeft }}>
      {[...Array(numbers).keys()].slice(1).map((num, idx) => (
        <p key={idx} style={{ ...type.LineNumbering.css }}>
          {num}
        </p>
      ))}
      <TooltipButton
        tipID="numbering"
        tip="Correct numbering will be shown when document is exported"
        placement="bottom"
      >
        <ButtonIcon icon="dotsHorizontal" />
      </TooltipButton>
    </div>
  );
};

Numbering.propTypes = {
  deal: PropTypes.object,
};

export default Numbering;

import { assign, filter, forEach, get, keys, pick, reduce } from 'lodash';

import { META_FIELD_TYPES, convertMetaToES } from '../server/search/Meta';
import Team, { FEATURES } from './Team';

export const META_FIELDS = {
  address: { type: META_FIELD_TYPES.TEXT },
  apiKey: {
    type: META_FIELD_TYPES.OBJECT,
    children: {
      createdBy: META_FIELD_TYPES.TEXT,
      dateCreated: META_FIELD_TYPES.DATE,
      fullName: META_FIELD_TYPES.TEXT,
    },
  },
  features: {
    type: META_FIELD_TYPES.OBJECT,
    children: reduce(
      FEATURES,
      (children, feature) => {
        children[feature.key] = META_FIELD_TYPES.BOOLEAN;
        return children;
      },
      {}
    ),
  },
  members: {
    type: META_FIELD_TYPES.NESTED,
    children: {
      email: META_FIELD_TYPES.TEXT,
      inviteID: META_FIELD_TYPES.STRING,
      observer: META_FIELD_TYPES.BOOLEAN,
      role: META_FIELD_TYPES.TEXT,
      status: META_FIELD_TYPES.TEXT,
      canCreateNewDoc: META_FIELD_TYPES.BOOLEAN,
      withoutRedline: META_FIELD_TYPES.BOOLEAN,
    },
  },
  membersCount: { type: META_FIELD_TYPES.FLOAT },
  name: { type: META_FIELD_TYPES.TEXT },
  org: { type: META_FIELD_TYPES.TEXT },
  observerCount: { type: META_FIELD_TYPES.FLOAT },
  teamID: { type: META_FIELD_TYPES.STRING },
  salesforceID: { type: META_FIELD_TYPES.TEXT },
  created: { type: META_FIELD_TYPES.DATE },
  updated: { type: META_FIELD_TYPES.DATE },
  themes: {
    type: META_FIELD_TYPES.NESTED,
    children: {
      description: META_FIELD_TYPES.TEXT,
      name: META_FIELD_TYPES.TEXT,
      isDefault: META_FIELD_TYPES.BOOLEAN,
      themeKey: META_FIELD_TYPES.STRING,
    },
  },
  themesCount: { type: META_FIELD_TYPES.FLOAT },
};
export default class TeamRecord {
  apiKey = {};
  features = {};
  address;
  name;
  integrations = [];
  members = [];
  membersCount = null;
  observerCount = null;
  teamID;
  salesforceID;
  themes = [];
  themesCount = null;
  created = null;
  updated = null;

  constructor(team) {
    // We can reuse TeamRecords from existing (untyped) search results
    if (!(team instanceof Team)) {
      assign(this, team);
      return;
    }

    if (!team.teamID) {
      throw new Error('A team ID is required.');
    }

    this.teamID = team.teamID;
    assign(this.apiKey, pick(team.apiKey, ['createdBy', 'dateCreated', 'fullName']));
    assign(this, pick(team.info, ['address', 'name', 'salesforceID', 'created', 'updated']));
    assign(this.features, team.features);

    if (team.users) {
      forEach(team.users, (value, key) => {
        const member = { uid: key };

        if (value.inviteID) {
          assign(member, pick(value, ['email', 'inviteID', 'role', 'status', 'observer']));
        } else {
          member.role = value;
          member.observer = team.userMemberships ? !!get(team.userMemberships[key], 'observer') : false;
          member.canCreateNewDoc = team.userMemberships ? !!get(team.userMemberships[key], 'canCreateNewDoc') : true;
          member.withoutRedline = team.userMemberships ? !!get(team.userMemberships[key], 'withoutRedline') : false;
        }

        this.members.push(member);
      });
    }

    if (team.themes) {
      forEach(team.themes, ({ description, name, themeKey, isDefault }) => {
        this.themes.push({ description, name, themeKey, isDefault });
      });
    }

    this.themesCount = this.themes.length;
    this.observerCount = filter(this.members, { observer: true }).length;
    this.membersCount = this.members.length;
  }

  get meta() {
    const meta = pick(this, keys(META_FIELDS));
    return convertMetaToES(meta, META_FIELDS);
  }
}

import React, { Component, createRef } from 'react';

import axios from 'axios';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DEAL_TYPE } from '@core/models/Deal';
import { DateFormatter } from '@core/utils/DateTime';
import { getDataURL } from '@core/utils/Generators';

import { Icon, Loader } from '@components/dmp';
import DropdownDots from '@components/dmp/DropdownDots';
import StatusLabel from '@components/dmp/StatusLabel';

import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import { getIcon } from '@components/deal/OriginIcon';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

const MenuItem = DropdownDots.MenuItem;

@autoBindMethods
export default class DealVersionItem extends Component {
  static propTypes = {
    version: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onCommit: PropTypes.func,
    interactive: PropTypes.bool,
    selected: PropTypes.bool,
    history: PropTypes.object,
  };

  static defaultProps = {
    onEdit: _.noop,
    onDelete: _.noop,
    onCommit: _.noop,
    interactive: true,
    selected: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      downloadURL: null,
      dataURL: null,
    };
    this.downloadLink = createRef();
  }

  componentDidMount() {
    const { version } = this.props;

    this._isMounted = true;

    // load dl url, put in state
    const bucketPath = version.origin === DEAL_TYPE.EXTERNAL_WORD ? version.docxBucketPath : version.pdfBucketPath;

    if (bucketPath) {
      Fire.storage
        .ref(bucketPath)
        .getDownloadURL()
        .then((downloadURL) => {
          if (this._isMounted) {
            this.setState({ downloadURL });
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get date() {
    const { version } = this.props;
    return `${DateFormatter.ymd(version.date, '-')} @ ${DateFormatter.time(version.date)}`;
  }

  get icon() {
    const { version } = this.props;
    if (this.state.downloading) return <Loader />;

    return (
      <TooltipButton tip={version.displayOrigin} placement="top" tipID={`version-icon-${version.key}`}>
        <div className="file-icon">{getIcon(version.origin)}</div>
      </TooltipButton>
    );
  }

  async handleAction(action) {
    const { onEdit, onDelete, onCommit, version, history } = this.props;
    const { downloadURL } = this.state;

    switch (action) {
      case 'edit':
        onEdit(version);
        break;
      case 'delete':
        onDelete(version);
        break;
      case 'download':
        if (downloadURL) {
          await this.setState({ downloading: true });
          // This is annoying: the href download attribute (for pretty filenames) only works
          // when the file (or data) is coming from current/same domain
          // Since it's hosted on Firebase storage, this will never be the case, so we need to download the full file locally,
          // and then push it through a data URL in order to achieve this (or, alternatively, proxy it through our API)
          // Since the file can be large, we may want to add a loading state, because this can take a few seconds...
          const raw = await axios.get(downloadURL, { responseType: 'arraybuffer' });
          const dataURL = getDataURL(raw.data);
          await this.setState({ dataURL, downloading: false });
          this.downloadLink.current.click();
        }
        break;
      case 'commit':
        onCommit(version);
        break;
      case 'view':
        if (history) {
          history.push(version.deepLink);
        }
        break;
      default:
        break;
    }
  }

  render() {
    const { version, interactive, selected, deleteAllowed } = this.props;
    const { downloadURL, dataURL } = this.state;

    if (!version) return null;

    const { date } = this;

    const className = cx('deal-file-block', { selected }, { interactive });

    const statusColor = _.get(version, 'step.color');

    return (
      <DealPanelItem borderBottom className={className} data-versionid={version.key} data-cy="version-panel">
        {this.icon}
        <div className="file-info" data-cy="file-info">
          <div className="file-topline" data-cy="file-topline">
            <div className="file-title" onClick={() => this.handleAction('view')}>
              Version {version.ordinal}
            </div>
            <div className="spacer" />
            {!version.isOriginal && <StatusLabel status={version.displayStatus} color={statusColor || undefined} />}

            {!version.isOriginal && interactive && (
              <DropdownDots
                pushRight={false}
                id={`dd-version-${version.key}`}
                onSelect={this.handleAction}
                dataCyToggle="version-dd-actions"
              >
                <MenuItem eventKey="edit" data-cy="edit-version">
                  Edit metadata
                </MenuItem>
                {deleteAllowed && (
                  <MenuItem eventKey="delete" data-cy="delete-version">
                    Delete version
                  </MenuItem>
                )}
                {downloadURL && (
                  <MenuItem eventKey="download" data-cy="download-version">
                    Download file
                  </MenuItem>
                )}
              </DropdownDots>
            )}
          </div>

          <div className="file-meta date">
            <Icon name="calendar" />
            <span>{date}</span>
          </div>

          {version.dealUser && (
            <div className="file-meta owner">
              <Icon name="profile" />
              <span>{version.dealUser.fullName}</span>
            </div>
          )}

          {version.origin !== 'native' && (
            <div className="file-meta file">
              <Icon name="document" />
              <span>{version.filename}</span>
            </div>
          )}

          {version.description && (
            <div className="file-meta description" data-cy="file-description">
              <Icon name="info" />
              <span>{version.description}</span>
            </div>
          )}
        </div>
        <a className="d-none" ref={this.downloadLink} href={dataURL} download={version.filename}>
          {version.filename}
        </a>
      </DealPanelItem>
    );
  }
}

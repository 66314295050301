import moment from 'moment-timezone';

import { getOrdinalSuffix } from './OrderFormatter';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const isDLST = (date) => {
  //checks if the date is in Daylight savings time
  const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  return july !== date.getTimezoneOffset();
};

const getOffset = (date) => {
  let offset;
  if (isDLST(date)) {
    offset = (new Date().getTimezoneOffset() + 60) * 60000;
  } else {
    offset = new Date().getTimezoneOffset() * 60000;
  }
  return offset;
};

const DateFormatter = {
  isDLST,
  getOffset,

  // e.g: November 3, 2021
  fromMilliseconds: (ms, short = false) => {
    const date = new Date(parseFloat(ms));
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const monthDisplay = short ? monthShortNames[month] : monthNames[month];
    return `${monthDisplay} ${day}, ${year}`;
  },

  // e.g: 349943000
  toMilliseconds: (date) => {
    return date.getTime().toString();
  },

  // e.g: 12/31/2021
  mdy: (date) => {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  },

  // e.g: 2021.12.31
  ymd: (date, separator) => {
    if (separator == null) separator = '.';

    let m = date.getMonth() + 1,
      d = date.getDate();
    if (m < 10) m = `0${m}`;
    if (d < 10) d = `0${d}`;
    return `${date.getFullYear()}${separator}${m}${separator}${d}`;
  },

  day: (date) => date.getDate().toString(),
  month: (date, languageCode) =>
    new Date(date).toLocaleDateString(languageCode, {
      month: 'long',
    }),
  year: (date) => date.getFullYear().toString(),
  dayOrdinal: (date) => date.getDate() + getOrdinalSuffix(date.getDate()),
  dayOfWeek: (date, languageCode) =>
    new Date(date).toLocaleDateString(languageCode, {
      weekday: 'long',
    }),
  verbose: (date) => `${DateFormatter.dayOrdinal(date)} day of ${DateFormatter.month(date)}, ${date.getFullYear()}`,

  // e.g: November 2021
  monthYearFromSeconds: (s) => {
    const date = new Date(parseFloat(s * 1000));

    var month = date.getMonth();
    var year = date.getFullYear();
    return `${monthNames[month]} ${year}`;
  },

  // e.g: 11:30 PM
  time: (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  },

  // e.g: 2021-12-31 12:12:12
  datetime: (date) => {
    return date
      .toISOString()
      .replace(/T|Z|\.\d{3}/g, ' ')
      .trim();
  },

  // enable any locale string to be used to format a Date variable! (see Variable.getDateProperty)
  // fallback to US if invalid
  locale: (date, locale) => {
    try {
      return date.toLocaleDateString(locale);
    } catch (er) {
      return date.toLocaleDateString('en-US');
    }
  },

  timezone: (date) => {
    let result = '';
    try {
      result = date.toLocaleString(navigator.language, { timeZoneName: 'short' }).split(' ').pop();
    } catch (err) {
      // error catched
    }
    return result;
  },

  //formatted timezone for ES date filtering
  timezoneIANA: () => {
    return moment.tz.guess();
  },

  //Build the API formatted date ("2022-01-01,2022-01-31")
  buildESDateFormat(date, dateTo) {
    let value = null;

    if (date) value = this.datetime(date).split(' ')[0];
    if (dateTo) value = value.concat(',', DateFormatter.datetime(dateTo).split(' ')[0]);

    return value;
  },

  date: (date, languageCode) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(languageCode, options);
  },

  daysFromNow: (days) => {
    return new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  },

  iso: (date) => {
    return date.toISOString().split('.')[0].replace(/[-:]/g, '');
  },

  locale12HourTime: (date, intl) => {
    return new Date(date).toLocaleString(intl.locale, {
      timeZone: intl.timeZone,
      hour12: true,
      timeZoneName: 'short',
    });
  },

  // Strip off the time of day and reconstruct a date as a local one,
  // To ensure a raw UTC value displays as the same day wherever we are (regardless of timezone)
  localizeUTC: (date) => {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    return new Date(year, month, day);
  },
};

// http://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time-eg-2-seconds-ago-one-week-ago-etc-best
const msPerSecond = 1000;
const msPerMinute = 60 * 1000;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;
const msPerMonth = msPerDay * 30;
const msPerYear = msPerDay * 365;

const Thresholds = [
  [msPerSecond, 'millisecond'],
  [msPerMinute, 'second'],
  [msPerHour, 'minute'],
  [msPerDay, 'hour'],
  [msPerMonth, 'day'],
  [msPerYear, 'month'],
  [msPerYear * 1000, 'year'],
];

function timeDifference(previous) {
  const elapsed = new Date() - previous;

  //start at 1 to avoid counts in milliseconds
  for (var i = 1; i < Thresholds.length; i++) {
    if (elapsed < Thresholds[i][0]) {
      const divisor = i > 0 ? Thresholds[i - 1][0] : 1;
      //round up to at least 1 to avoid saying '0 seconds'
      const num = Math.max(Math.round(elapsed / divisor), 1);
      // return `${i >= 4 ? 'approximately ' : ''}${num} ${Thresholds[i][1]}${num > 1 ? 's' : ''} ago`
      return `${num} ${Thresholds[i][1]}${num > 1 ? 's' : ''} ago`;
    }
  }
}

export { DateFormatter, timeDifference };
